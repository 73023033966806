import { Link, useNavigate, useParams } from "react-router-dom";
import Dash_Header from "../components/Dashheader";
import { useEffect, useState } from "react";
import { BASEURL } from "../common/config";
import axios from "axios";

export default function DashEditProduct() {
  const { _id } = useParams(); // Get the product ID from the URL
  const [product, setProduct] = useState('');
  const [price, setPrice] = useState('');
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState('');
  const [stock, setStock] = useState('');
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const [existingImage, setExistingImage] = useState('');
  const navigate = useNavigate();

  // Fetch product details when the component is mounted
  useEffect(() => {
    if (!_id) {
      alert("No product ID found in URL");
      navigate("/dash-manage-product"); // Redirect if no ID is found
      return;
    }

    const fetchProductData = async () => {
      try {
        const res = await axios.get(`${BASEURL}/product/${_id}`);
        const productData = res.data;
        
        setProduct(productData.name);
        setPrice(productData.price);
        setStock(productData.stock);
        setDescription(productData.description);
        setExistingImage(productData.image);
      } catch (error) {
        console.error("Error fetching product data:", error);
        alert("Error fetching product data. Please try again.");
      }
    };

    fetchProductData();
  }, [_id, navigate]);

  // Function to upload image to Cloudinary
  const uploadImageToCloudinary = async (image) => {
    const formData = new FormData();
    formData.append("file", image);
    formData.append("upload_preset", "yzuqi0jw"); // Cloudinary upload preset

    try {
      const res = await axios.post("https://api.cloudinary.com/v1_1/dpkgdtztx/upload", formData);
      return res.data.secure_url; // Return the URL of the uploaded image
    } catch (error) {
      console.error("Error uploading image:", error);
      throw new Error("Image upload failed");
    }
  };

  // Function to handle the product update
  const postProduct = async (e) => {
    e.preventDefault();

    if (!product || !price || (!image && !existingImage) || !description || !stock) {
      setErr(true);  // Set error state if required fields are missing
      return;
    }

    setLoading(true);
    const adminToken = localStorage.getItem("smeemly-admin-token");

    try {
      let imageUrl = existingImage;  // Start with the existing image

      // Upload the new image if one is selected
      if (image) {
        imageUrl = await uploadImageToCloudinary(image);
      }

      // Prepare the product data
      const productData = {
        name: product,
        description,
        price,
        stock,
        image: imageUrl,  // Use either the newly uploaded image or the existing one
      };

      // Update the product in the backend
      const headers = { Authorization: `Bearer ${adminToken}` };
      const res = await axios.put(`${BASEURL}/product/${_id}`, productData, { headers });

      if (res.status === 200) {
        alert(res.data.message); // Show success message
        navigate("/dash-manage-product"); // Redirect to manage products page
      } else {
        alert("Could not update product, please try again");
      }
    } catch (err) {
      console.error(err);
      alert("An error occurred, please try again");
    } finally {
      setLoading(false);  // Stop the loading state
    }
  };

  return (
    <div>
      <div className="page-wraper">
        <Dash_Header />
        <div id="content">
          <div className="content-admin-main">
            <div className="wt-admin-right-page-header clearfix">
              <h2>Edit Product</h2>
              <div className="breadcrumbs">
                <Link to="/">Home</Link>
                <Link to="/dash-manage-product">Dashboard</Link>
                <span>Edit Product</span>
              </div>
            </div>

            <div className="panel panel-default">
              <div className="panel-heading wt-panel-heading p-a20">
                <h4 className="panel-title m-a0">
                  <i className="fa fa-suitcase"></i>Product Details
                </h4>
              </div>
              <div className="panel-body wt-panel-body p-a20 m-b30">
                <form onSubmit={postProduct}>
                  <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-12">
                      <div className="form-group">
                        <label>Name</label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Bag"
                          onChange={(e) => setProduct(e.target.value)}
                          value={product}
                        />
                        {err && !product && <span style={{ color: "red" }}>Enter product name</span>}
                      </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-12">
                      <div className="form-group">
                        <label>Price</label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="40000"
                          onChange={(e) => setPrice(e.target.value)}
                          value={price}
                        />
                        {err && !price && <span style={{ color: "red" }}>Enter price</span>}
                      </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-12">
                      <div className="form-group">
                        <label>Stock</label>
                        <input
                          className="form-control"
                          type="number"
                          placeholder="100"
                          onChange={(e) => setStock(e.target.value)}
                          value={stock}
                        />
                        {err && !stock && <span style={{ color: "red" }}>Enter stock</span>}
                      </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-12">
                      <div className="form-group">
                        <label>Image</label>
                        <input
                          className="form-control"
                          type="file"
                          onChange={(e) => setImage(e.target.files[0])}
                        />
                        {err && !image && !existingImage && <span style={{ color: "red" }}>Enter image</span>}
                      </div>
                    </div>

                    {existingImage && (
                      <div className="col-xl-4 col-lg-6 col-md-12">
                        <img
                          src={existingImage}
                          alt="Existing Product"
                          style={{ width: '100%', height: 'auto' }}
                          onError={(e) => { e.target.src = 'path/to/placeholder-image.jpg'; }} // Fallback for broken images
                        />
                      </div>
                    )}

                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Description</label>
                        <textarea
                          className="form-control"
                          rows="3"
                          placeholder="Description of the product"
                          onChange={(e) => setDescription(e.target.value)}
                          value={description}
                        ></textarea>
                        {err && !description && <span style={{ color: "red" }}>Enter description</span>}
                      </div>
                    </div>
                  </div>

                  <button
                    className={`btn btn-primary ${loading ? "disabled" : ""}`}
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? 'Loading...' : 'Update Product'}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
