import { Link, useNavigate } from "react-router-dom"

import { useEffect, useState } from "react"
import { BASEURL } from "../../common/config"
import ComapnyNav from "../../components/CompanyNav"
import PublicHeader from "../../components/PublicHeader"
import axios from "axios"


export default function StoreOrders() {
    const storeDetails = JSON.parse(localStorage.getItem("store-details"));
    // const comDetails = JSON.parse(localStorage.getItem("com-details"));
    const token = localStorage.getItem("store-token");
    // const navigate = useNavigate()
    // const [jobs, setJobs] = useState([])

    const [orders, setOrders] = useState()
    useEffect(()=>{
        axios.get(`${BASEURL}/store-orders/${storeDetails._id}`)
        .then((res)=>{
            console.log(res.data.data)
            let orderData = res.data.data.reverse()
            let orders = orderData.filter((data)=> data.user_id  != null && data.product_id != null) 
                setOrders(orders)
        }).catch((err)=>{
            console.log(err)
        })
    },[])


    // const deleteData = (id) => {
   
    //     const shouldDelete = window.confirm("Are you sure you want to delete this product?");

    //     if (!shouldDelete) {
    //         return;
    //     }
    //     const headers = { Authorization: `Bearer ${token}`};
    //     console.log(token)

    //     let apiUrl = `http://localhost:7000/product-delete/${id}`

    //     axios.delete(apiUrl, {headers})
    //     .then((res)=>{
    //       console.log(res)
    //       setOrders(prevProducts => prevProducts.filter(product => product._id !== id));
    //     })
    //     .catch((err)=>{
    //         console.log(err)
    //     })
    // }

    return (

        <div>
            {/* <!-- HEADER START --> */}
            <PublicHeader />
            {/* <!-- HEADER END --> */}
            {/* <!-- CONTENT START --> */}
            <div class="page-content">

                {/* <!-- OUR BLOG START --> */}
                <div class="section-full p-t120  p-b90 site-bg-white">


                    <div class="container">
                        <div class="row">

                            <div class="col-xl-3 col-lg-4 col-md-12 rightSidebar m-b30">
                                <ComapnyNav />
                            </div>

                            <div class="col-xl-9 col-lg-8 col-md-12 m-b30">
                                <div class="twm-right-section-panel candidate-save-job site-bg-gray">
                                    {/* <!--Filter Short By--> */}
                                    <div class="product-filter-wrap d-flex justify-content-between align-items-center">
                                        <span class="woocommerce-result-count-left"> {""} Orders</span>
                                        {/* <span class="woocommerce-result-count-right"> <Link to="/store-post-product" className="btn btn-primary">Create Ptoduct</Link></span> */}
                                    </div>

                                    <table id="" className="table table-bordered twm-bookmark-list-wrap">
                                        <thead>
                                            <tr>
                                                <th>Product</th>
                                                <th>Client Name</th>
                                                <th>Client Phone</th>
                                                <th>Client Email</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* <!--1--> */}
                                            {
                                                orders && orders.map((prod) => (
                                                    <tr>

                                                        <td>
                                                          {prod.product_id.prodName}
                                                        </td>
                                                        <td>{prod.user_id.fullName}</td>
                                                        <td>{prod.user_id.phone}</td>
                                                        <td>{prod.user_id.email}</td>
                                                  

                                                        <td>
                                                            <div className="twm-table-controls">
                                                                <ul className="twm-DT-controls-icon list-unstyled">
                                                                  
                                                                    {/* <li>
                                                                        <button onClick={() => deleteData(prod._id)} title="Delete" data-bs-toggle="tooltip" data-bs-placement="top">
                                                                            <span className="far fa-trash-alt"></span>
                                                                        </button>
                                                                    </li> */}
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            }


                                        </tbody>
                                        <tfoot>
                                            <tr>
                                            <th>Product</th>
                                                <th>Client Name</th>
                                                <th>Client Phone</th>
                                                <th>Client Email</th>
                                                <th>Action</th>
                                            </tr>
                                        </tfoot>
                                    </table>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* <!-- OUR BLOG END --> */}



            </div>
            {/* <!-- CONTENT END --> */}
        </div>

    )
}