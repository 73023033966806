import { Link, useNavigate } from "react-router-dom";
import Dash_Header from "../components/Dashheader";
import { useEffect, useState } from "react";
import { BASEURL } from "../common/config";

export default function DashManageProduct() {
    const navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true); // State for loading
    const token = localStorage.getItem("smeemly-admin-token");

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const res = await fetch(`${BASEURL}/product`);
                const data = await res.json();
                console.log(data);
                setProducts(data); // Directly set products without filtering for store_id
            } catch (err) {
                console.error(err);
                alert("Failed to fetch products. Please try again later.");
            } finally {
                setLoading(false); // Stop loading state
            }
        };
        fetchProducts();
    }, []);

    const deleteData = async (_id) => {
        const shouldDelete = window.confirm("Are you sure you want to delete this product?");
        if (!shouldDelete) return;

        const headers = {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
        };
        const apiUrl = `${BASEURL}/product/${_id}`;
        console.log(apiUrl);

        try {
            const res = await fetch(apiUrl, {
                method: "DELETE",
                headers
            });

            if (res.ok) {
                const result = await res.json();
                alert(result.message);
                setProducts((prevProducts) => prevProducts.filter(product => product._id !== _id));
            } else {
                alert("Could not delete product");
            }
        } catch (err) {
            console.error(err);
            alert("An error occurred. Please try again.");
        }
    };

    const editProduct = (_id) => {
        navigate(`/dash-edit-product/${_id}`)
        console.log(_id);
        ;
    };

    if (loading) {
        return <div>Loading...</div>; // Loading state
    }

    return (
        <div>
            <div className="page-wraper">
                <Dash_Header />

                <div id="content">
                    <div className="content-admin-main">
                        <div className="wt-admin-right-page-header clearfix">
                            <h2>Manage Products</h2>
                            <div className="breadcrumbs">
                                <Link to="#">Home</Link>
                                <Link to="#">Dashboard</Link>
                                <span>My Product Listing</span>
                            </div>
                        </div>

                        <div className="panel panel-default">
                            <div className="panel-heading wt-panel-heading p-a20">
                                <div className="row">
                                    <div className="col">
                                        <h4 className="panel-title m-a0">
                                            <i className="fa fa-suitcase"></i> Product Details
                                        </h4>
                                    </div>
                                    <div className="col-auto">
                                        <Link to="/dash-post-product" className="btn btn-primary">Post a Product</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-body wt-panel-body p-a20 m-b30">
                                <div className="twm-D_table p-a20 table-responsive">
                                    <table id="products_bookmark_table" className="table table-bordered twm-bookmark-list-wrap">
                                        <thead>
                                            <tr>
                                                <th>Product image</th>
                                                <th>Product</th>
                                                <th>Price</th>
                                                <th>Description</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {products.length === 0 ? (
                                                <tr>
                                                    <td colSpan="5" className="text-center">No products available</td>
                                                </tr>
                                            ) : (
                                                products.map((prod) => (
                                                    <tr key={prod._id}>
                                                        <td>
                                                            <div className="twm-bookmark-list">
                                                                <div className="twm-media">
                                                                    <div className="twm-media-pic">
                                                                        <img src={prod.image} alt={prod.prodName} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>{prod.name}</td>
                                                        <td>{prod.price}</td>
                                                        <td>{prod.description}</td>
                                                        <td>
                                                            <div className="twm-table-controls">
                                                                <ul className="twm-DT-controls-icon list-unstyled">
                                                                    <li>
                                                                        <button title="View profile">
                                                                            <span className="fa fa-eye"></span>
                                                                        </button>
                                                                    </li>
                                                                    <li>
                                                                        <button onClick={() => editProduct(prod._id)} title="Edit">
                                                                            <span className="far fa-edit"></span>
                                                                        </button>
                                                                    </li>
                                                                    <li>
                                                                        <button onClick={() => deleteData(prod._id)} title="Delete">
                                                                            <span className="far fa-trash-alt"></span>
                                                                        </button>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th>Product image</th>
                                                <th>Product</th>
                                                <th>Price</th>
                                                <th>Description</th>
                                                <th>Action</th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
