import { Link } from "react-router-dom";
import PublicHeader from "../../components/PublicHeader";
import UserNav from "../../components/UserNav";
import { useEffect, useState } from "react";
import { BASEURL } from "../../common/config";
import axios from "axios";
import ComapnyNav from "../../components/CompanyNav";
export default function StoreEditProfile() {
  const storeDetails = JSON.parse(localStorage.getItem("store-details"));
  console.log(storeDetails._id)
  let store_id = storeDetails._id
  const token = localStorage.getItem("store-token");

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [userData, setUserData] = useState({});
  const [fullName, setFullName] = useState("");
  const [storeName, setStoreName] = useState("");
  const [location, setLocation] = useState("");
  const [about, setAbout] = useState("");
  const [newPhoto, setNewPhoto] = useState("")
  const [banner, setNewBanner] = useState("")
  const [formImage, setFormImage] = useState("");
  const [formBanner, setFormBanner] = useState("");
  const [loading, setLoading]= useState(false)

  useEffect(() => {
    axios
      .get(`${BASEURL}/store/${storeDetails._id}`)
      .then((res) => {
        console.log(res.data.data);
        setFullName(res.data.data.fullName);
        setEmail(res.data.data.email);
        let phone = res.data.data.phone.slice(3, 13);
        console.log(phone);
        let modifyPhone = `0${phone}`;
        setPhone(modifyPhone);
        setAbout(res.data.data.about);
        setLocation(res.data.data.location);
        setStoreName(res.data.data.storeName);
        setFormBanner(res.data.data.logo);
        setFormImage(res.data.data.profile_photo);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const states = [
    "Abia", "Adamawa", "Akwa Ibom", "Anambra", "Bauchi", "Bayelsa", "Benue", "Borno",
    "Cross River", "Delta", "Ebonyi", "Edo", "Ekiti", "Enugu", "Gombe", "Imo", "Jigawa",
    "Kaduna", "Kano", "Katsina", "Kebbi", "Kogi", "Kwara", "Lagos", "Nasarawa", "Niger",
    "Ogun", "Ondo", "Osun", "Oyo", "Plateau", "Rivers", "Sokoto", "Taraba", "Yobe", "Zamfara",
    "Abuja" 
  ];
  

  const uploadImage = async (uploadImg) => {

    const data = new FormData();
    data.append("file", uploadImg);
    data.append( "upload_preset", process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET);
    data.append("cloud_name", process.env.REACT_APP_CLOUDINARY_CLOUD_NAME);
    data.append("folder", "Cloudinary-React");
    console.log(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/auto/upload`)

    try {
      const response = await fetch( `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/auto/upload`,
        {
          method: "POST",
          body: data,
        }
      );
      const res = await response.json();
      console.log(res)
     
    
      return res.secure_url
      
    } catch (error) {
 
      return null
    }
  };

const uploadBanner = async (uploadImg) => {

  const data = new FormData();
  data.append("file", uploadImg);
  data.append( "upload_preset", process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET);
  data.append("cloud_name", process.env.REACT_APP_CLOUDINARY_CLOUD_NAME);
  data.append("folder", "Cloudinary-React");
  console.log(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/auto/upload`)

  try {
    const response = await fetch( `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/auto/upload`,
      {
        method: "POST",
        body: data,
      }
    );
    const res = await response.json();
    console.log(res)
   
  
    return res.secure_url
    
  } catch (error) {

    return null
    }
  };

const editSctore = async (e) => {
  e.preventDefault();
 

  let imageURL= await uploadImage(newPhoto)
  console.log(imageURL)

  let bannerURL= await uploadBanner(banner)
  console.log(bannerURL)


  
  setLoading(true)
  const storeDetails = {
   
    storeName: storeName,
      phone:phone,
      email:email,
      fullName:fullName,
      location:location,
      profile_photo:imageURL,
      about: about,
      logo:bannerURL,
  }
  console.log(storeDetails)
  setLoading(true)

  let apiUrl = `${BASEURL}/store/update/${store_id}`
  console.log(apiUrl)

  let headers = {
    "Authorization" : `Bearer ${token}`
  }
  axios.put(apiUrl, storeDetails, {headers})
  .then((res)=>{
    console.log(res)
    if (res.status && res.status === 200) {
      alert(res.data.message); // Alert the success message from the server
    } else {
      alert("Could not create store. Please try again."); // Alert a generic error message for unexpected responses
    }
  })
  .catch((err)=>{
    console.log(err)
    if (err.response && err.response.data && err.response.data.message) {
      alert(err.response.data.message); // Alert the error message from the server
    } else {
      alert("An error occurred. Please try again."); // Alert a generic error message if no specific message is available
    }
  })
  .finally(()=>{
    setLoading(false )
  })
  

};

  return (
    <div>
      {/* <!-- HEADER START --> */}
      <PublicHeader />
      {/* <!-- HEADER END --> */}
      {/* <!-- CONTENT START --> */}
      <div class="page-content">
        {/* <!-- OUR BLOG START --> */}
        <div class="section-full p-t120  p-b90 site-bg-white">
          <div class="container">
            <div class="row">
              <div class="col-xl-3 col-lg-4 col-md-12 rightSidebar m-b30">
                <ComapnyNav />
              </div>

              <div class="col-xl-9 col-lg-8 col-md-12 m-b30">
                {/* <!--Filter Short By--> */}
                <div class="twm-right-section-panel site-bg-gray">
                  <form onSubmit={editSctore}>
                    <div className="panel panel-default">
                      <div className="panel-heading wt-panel-heading p-a20">
                        <h4 className="panel-tittle m-a0">
                          Logo and Cover image
                        </h4>
                      </div>
                      <div className="col-lg-12 col-md-12 ">
                        <div className="dashboard-cover-pic">
                          <div
                            action="https://thewebmax.org/jobzilla/upload.php"
                            className="dropzone "
                          >
                            <div className="row mb-5">
                              <div className="col-lg-6 ">
                                <label className="mb-3 fw-bolder" htmlFor="">
                                  Store Logo
                                </label>
                                {/* <input type="file" placeholder="input company logo"  onChange={(e) => setFormImage(e.target.value)} className="form-control" /> */}

                                <figure className="h-75 w-100 ">
                                  <img
                                    src={formImage}
                                    alt=""
                                    className="h-100 w-100"
                                  />
                                </figure>
                              </div>
                              <div className="col-lg-6 ">
                                <label className="mb-3 fw-bolder" htmlFor="">
                                  Store Banner
                                </label>
                                {/* <input type="file" placeholder="input company baner image"  onChange={(e) => setFormBanner(e.target.value)} className="form-control" /> */}
                                <figure className="w-100 h-75">
                                  <img
                                    src={formBanner}
                                    alt=""
                                    className="w-100 h-100"
                                  />
                                </figure>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!--Basic Information--> */}
                    <div class="panel panel-default">
                      <div class="panel-heading wt-panel-heading p-a20">
                        <h4 class="panel-tittle m-a0">Basic Informations</h4>
                      </div>
                      <div class="panel-body wt-panel-body p-a20 m-b30 ">
                        
                        <div class="row">
                          <div class="col-xl-6 col-lg-6 col-md-12">
                            <div class="form-group">
                              <label>Holder Name</label>
                              <div class="ls-inputicon-box">
                                <input
                                  class="form-control"
                                  name="company_name"
                                  type="text"
                                  
                                  onChange={(e) => setFullName(e.target.value)}
                                  value={fullName}
                                  placeholder="Devid Smith"
                                />
                                <i class="fs-input-icon fa fa-user "></i>
                              </div>
                            </div>
                          </div>

                          <div class="col-xl-6 col-lg-6 col-md-12">
                            <div class="form-group">
                              <label>Phone</label>
                              <div class="ls-inputicon-box">
                                <input
                                  class="form-control"
                                  name="company_phone"
                                  
                                  onChange={(e) => setPhone(e.target.value)}
                                  value={phone}
                                  type="text"
                                  placeholder="e.g (251) 1234-456-7890"
                                />
                                <i class="fs-input-icon fa fa-phone-alt"></i>
                              </div>
                            </div>
                          </div>

                          <div class="col-xl-6 col-lg-6 col-md-12">
                            <div class="form-group">
                              <label>Email Address</label>
                              <div class="ls-inputicon-box">
                                <input
                                  class="form-control"
                                  name="company_Email"
                                  
                                  onChange={(e) => setEmail(e.target.value)}
                                  value={email}
                                  type="email"
                                  placeholder="e.g Devid@example.com"
                                />
                                <i class="fs-input-icon fas fa-at"></i>
                              </div>
                            </div>
                          </div>

                          <div class="col-xl-6 col-lg-6 col-md-12">
                            <div class="form-group">
                              <label>Store</label>
                              <div class="ls-inputicon-box">
                                <input
                                  class="form-control"
                                  name="company_name"
                                  type="text"
                                  
                                  onChange={(e) => setStoreName(e.target.value)}
                                  value={storeName}
                                  placeholder="e.g Microsoft Inc"
                                />
                                <i class="fs-input-icon fa fa-building "></i>
                              </div>
                            </div>
                          </div>

                          <div class="col-xl-6 col-lg-6 col-md-12">
                            <div class="form-group">
                              <label>Profile Photo</label>
                              <div class="ls-inputicon-box">
                                <input
                                  class="form-control"
                                  name="company_name"
                                  type="file"
                                  
                                  onChange={(e) => setNewPhoto(e.target.files[0])}
                                  // value={storeName}
                                  placeholder="e.g Microsoft Inc"
                                />
                                <i class="fs-input-icon fa fa-building "></i>
                              </div>
                            </div>
                          </div>

                          <div class="col-xl-6 col-lg-6 col-md-12">
                            <div class="form-group">
                              <label>Banner </label>
                              <div class="ls-inputicon-box">
                                <input
                                  class="form-control"
                                  name="company_name"
                                  type="file"
                                  
                                  onChange={(e) => setNewBanner(e.target.files[0])}
                                  // value={storeName}
                                  placeholder="e.g Microsoft Inc"
                                />
                                <i class="fs-input-icon fa fa-building "></i>
                              </div>
                            </div>
                          </div>


                          <div class="col-xl-6 col-lg-6 col-md-12">
                            <div class="form-group">
                              <label>About</label>
                              <div class="ls-inputicon-box">
                                <input
                                  class="form-control"
                                  name="company_name"
                                  type="text"
                                  
                                  onChange={(e) => setAbout(e.target.value)}
                                  value={about}
                                  placeholder="e.g Microsoft Inc"
                                />
                                <i class="fs-input-icon fa fa-building "></i>
                              </div>
                            </div>
                          </div>
                          <div class="col-xl-6 col-lg-6 col-md-12">
                            <div class="form-group">
                              <label>Location </label>
                              <div class="ls-inputicon-box">
                              <select
  className="form-control"
  name="company_name"
  value={storeName}
  onChange={(e) => setStoreName(e.target.value)}
>
  <option value="">Select a state</option>
  {states.map((state) => (
    <option key={state} value={state}>{state}</option>
  ))}
</select>

                                <i class="fs-input-icon fa fa-building "></i>
                              </div>
                            </div>
                          </div>
                       

                          <div class="col-lg-12 col-md-12">
                                                        <div class="text-left">
                                                            <button type="submit" class="site-button">{loading ? "Updating..." : "Save Changes"}</button>
                                                        </div>
                                                    </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- OUR BLOG END --> */}
      </div>
      {/* <!-- CONTENT END --> */}
    </div>
  );
}
