import Dash_Header from "../../components/Dashheader";
import Footer from "../../components/Footer";
function Testimonials() {
  return (
    <>
      <div className="page-wraper">
        <Dash_Header />

        <div id="content">
          <div
            style={{
              backgroundColor: "white",
              color: "black",
              padding: "60px 0px",
            }}
          >
            {/* Heading */}
            <div className="text-center mb-5 mt-4">
              <h1 style={{ fontSize: "3rem", fontWeight: "bold", color: "black", }}>
                Testimonials
              </h1>
            </div>

            {/* Testimonials */}
            <div className="container">
              {/* First Row */}
              <div className="row mb-5">
                {/* Testimonial 1 */}
                <div className="col-12 col-lg-6 d-flex align-items-start gap-4 mb-4">
                  <img
                    src="https://duyn491kcolsw.cloudfront.net/files/1v/1vg/1vggel.png?ph=1ff354f48f"
                    alt="Person 1"
                    className="rounded-circle"
                    style={{ width: "100px", height: "100px" }}
                  />
                  <div>
                    <h2 style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                      Mary Mitchell
                    </h2>
                    <span
                      style={{
                        fontSize: "1.25rem",
                        fontStyle: "italic",
                        color: "#aaa",
                      }}
                    >
                      Data Security Analyst
                    </span>
                    <p
                      style={{
                        marginTop: "10px",
                        fontSize: "1rem",
                        lineHeight: "1.8",
                      }}
                    >
                      "Thanks to the expertise of the cyber forensics team, we
                      were able to successfully identify and stop a malicious
                      hacker who had been targeting our company's sensitive
                      data. Their quick response and thorough investigation
                      saved us from potential financial and reputational
                      damage."
                    </p>
                  </div>
                </div>

                {/* Testimonial 2 */}
                <div className="col-12 col-lg-6 d-flex align-items-start gap-4 mb-4">
                  <img
                    src="https://duyn491kcolsw.cloudfront.net/files/3l/3li/3lik65.png?ph=1ff354f48f"
                    alt="Person 2"
                    className="rounded-circle"
                    style={{ width: "100px", height: "100px" }}
                  />
                  <div>
                    <h2 style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                      Joe Carter
                    </h2>
                    <span
                      style={{
                        fontSize: "1.25rem",
                        fontStyle: "italic",
                        color: "#aaa",
                      }}
                    >
                      Information Security Consultant
                    </span>
                    <p
                      style={{
                        marginTop: "10px",
                        fontSize: "1rem",
                        lineHeight: "1.8",
                      }}
                    >
                      "Without the help of cyber forensics, we would have never
                      known the extent of the data breach that occurred in our
                      organization. Their in-depth analysis and detailed report
                      not only helped us identify the source of the breach but
                      also provided us with valuable insights to prevent future
                      incidents."{" "}
                    </p>
                  </div>
                </div>
              </div>

              {/* Second Row */}
              <div className="row">
                {/* Testimonial 3 */}
                <div className="col-12 col-lg-6 d-flex align-items-start gap-4 mb-4">
                  <img
                    src="https://duyn491kcolsw.cloudfront.net/files/3b/3b1/3b1651.png?ph=1ff354f48f"
                    alt="Person 3"
                    className="rounded-circle"
                    style={{ width: "100px", height: "100px" }}
                  />
                  <div>
                    <h2 style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                      Peter Miller
                    </h2>
                    <span
                      style={{
                        fontSize: "1.25rem",
                        fontStyle: "italic",
                        color: "#aaa",
                      }}
                    >
                      Digital Forensics Investigator
                    </span>
                    <p
                      style={{
                        marginTop: "10px",
                        fontSize: "1rem",
                        lineHeight: "1.8",
                      }}
                    >
                      "As a victim of cyber fraud, I was devastated and didn't
                      know where to turn. Thankfully, I was referred to a cyber
                      forensics specialist who helped me recover valuable
                      evidence and build a strong case against the perpetrator.
                      Their knowledge and support were invaluable in seeking
                      justice."{" "}
                    </p>
                  </div>
                </div>

                {/* Testimonial 4 */}
                <div className="col-12 col-lg-6 d-flex align-items-start gap-4 mb-4">
                  <img
                    src="https://duyn491kcolsw.cloudfront.net/files/30/304/304xff.png?ph=1ff354f48f"
                    alt="Person 4"
                    className="rounded-circle"
                    style={{ width: "100px", height: "100px" }}
                  />
                  <div>
                    <h2 style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                      Ashley Elegant
                    </h2>
                    <span
                      style={{
                        fontSize: "1.25rem",
                        fontStyle: "italic",
                        color: "#aaa",
                      }}
                    >
                      Founder, Creatives Studio
                    </span>
                    <p
                      style={{
                        marginTop: "10px",
                        fontSize: "1rem",
                        lineHeight: "1.8",
                      }}
                    >
                      Using blockchain analysis tools to trace and map
                      transactions on the blockchain to uncover patterns and
                      relationships between different entities. Thanks...
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                {/* Testimonial 3 */}
                <div className="col-12 col-lg-6 d-flex align-items-start gap-4 mb-4">
                  <img
                    src="https://duyn491kcolsw.cloudfront.net/files/07/07p/07ptff.png?ph=1ff354f48f"
                    alt="Person 3"
                    className="rounded-circle"
                    style={{ width: "100px", height: "100px" }}
                  />
                  <div>
                    <h2 style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                    Anita Wilson
                    </h2>
                    <span
                      style={{
                        fontSize: "1.25rem",
                        fontStyle: "italic",
                        color: "#aaa",
                      }}
                    >
                      Digital Marketing Coordinator
                    </span>
                    <p
                      style={{
                        marginTop: "10px",
                        fontSize: "1rem",
                        lineHeight: "1.8",
                      }}
                    >
                      "I was blown away by the level of professionalism and expertise displayed by the cyber forensics team. They were able to uncover critical evidence in a fraud case that we thought was unsolvable. Thanks to their efforts, justice was served. I cannot thank them enough!"
                    </p>
                  </div>
                </div>

                {/* Testimonial 4 */}
                <div className="col-12 col-lg-6 d-flex align-items-start gap-4 mb-4">
                  <img
                    src="https://duyn491kcolsw.cloudfront.net/files/1a/1a3/1a3o8o.png?ph=1ff354f48f"
                    alt="Person 4"
                    className="rounded-circle"
                    style={{ width: "100px", height: "100px" }}
                  />
                  <div>
                    <h2 style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                    Martin Davis
                    </h2>
                    <span
                      style={{
                        fontSize: "1.25rem",
                        fontStyle: "italic",
                        color: "#aaa",
                      }}
                    >
                      Information Security Specialist
                    </span>
                    <p
                      style={{
                        marginTop: "10px",
                        fontSize: "1rem",
                        lineHeight: "1.8",
                      }}
                    >
                      "As a law enforcement officer, I have worked with many cyber forensics experts, but none have impressed me as much as this team. Their knowledge and dedication to solving cybercrime cases is unparalleled. They truly are the best in the business."
                    </p>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          <Footer/>
        </div>
      </div>
    </>
  );
}

export default Testimonials;
