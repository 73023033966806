import { Link } from "react-router-dom";
import logo from "../assets/images/images/logo-dark.png";
import Dash_Header from "../components/Dashheader";
import { useState } from "react";
import { BASEURL } from "../common/config";
import axios from "axios";
// import banner from "../assets/images/images/background/bg-3.jpg"
// import banner2 from "../assets/images/images/background/bg-2.jpg"

export default function CreateStore() {
  const [profile_photo,setprofile_photo ] = useState();
  const [logo, setLogo ] = useState();
  const [fullName, setfullName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [location, setlocation] = useState();
  const [phone, setPhone] = useState();
  const [storeName, SetstoreName] = useState();
  const [about, setAbout] = useState();
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false)


  const uploadImage = async (uploadImg) => {

    const data = new FormData();
    data.append("file", uploadImg);
    data.append( "upload_preset", process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET);
    data.append("cloud_name", process.env.REACT_APP_CLOUDINARY_CLOUD_NAME);
    data.append("folder", "Cloudinary-React");
    console.log(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/auto/upload`)

    try {
      const response = await fetch( `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/auto/upload`,
        {
          method: "POST",
          body: data,
        }
      );
      const res = await response.json();
      console.log(res)
     
    
      return res.secure_url
      
    } catch (error) {
 
      return null
    }
  };

const uploadLogo = async (uploadImg) => {

  const data = new FormData();
  data.append("file", uploadImg);
  data.append( "upload_preset", process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET);
  data.append("cloud_name", process.env.REACT_APP_CLOUDINARY_CLOUD_NAME);
  data.append("folder", "Cloudinary-React");
  console.log(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/auto/upload`)

  try {
    const response = await fetch( `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/auto/upload`,
      {
        method: "POST",
        body: data,
      }
    );
    const res = await response.json();
    console.log(res)
   
  
    return res.secure_url
    
  } catch (error) {

    return null
    }
  };


  const CreateCompany = async (e) => {
    e.preventDefault();
    if (
      !profile_photo  ||
      !storeName  ||
      !phone  ||
      !email  ||
      !fullName  ||
      !location  ||
      !about ||
      !password || 
      !logo
    ) {
      setErr(true);
      return;
      
    }

    let imageURL= await uploadImage(profile_photo)
    console.log(imageURL)

    let logoURL = await uploadLogo(logo)
    console.log(logoURL)

    
    const storeDetails = {
      profile_photo:profile_photo,
      storeName: storeName,
        phone:phone,
        email:email,
        fullName:fullName,
        location:location,
        profile_photo:imageURL,
        about: about,
        logo:logoURL,
        password:password
    }
    console.log(storeDetails)
    setLoading(true)

    let apiUrl = `${BASEURL}/auth/create-store`
    axios.post(apiUrl, storeDetails)
    .then((res)=>{
      console.log(res)
      if (res.data && res.data.status === "Success") {
        alert(res.data.message); // Alert the success message from the server
      } else {
        alert("Could not create store. Please try again."); // Alert a generic error message for unexpected responses
      }
    })
    .catch((err)=>{
      console.log(err)
      if (err.response && err.response.data && err.response.data.message) {
        alert(err.response.data.message); // Alert the error message from the server
      } else {
        alert("An error occurred. Please try again."); // Alert a generic error message if no specific message is available
      }
    })
    .finally(()=>{
      setLoading(false )
    })
    
 
  };

  return (
    <body>
      <div className="page-wraper">
        <Dash_Header />

        {/* <!-- Page Content Holder --> */}
        <div id="content">
          <div className="content-admin-main">
            <div className="wt-admin-right-page-header clearfix">
              <h2>Store Profile</h2>
              <div className="breadcrumbs">
                <a href="#">Home</a>
                <a href="#">Dasboard</a>
                <span>Store Profile</span>
              </div>
            </div>

            {/* <!--Logo and Cover image--> */}
            <form onSubmit={CreateCompany}>

           

            {/* <!--Basic Information--> */}
            <div className="panel panel-default">
              <div className="panel-heading wt-panel-heading p-a20">
                <h4 className="panel-tittle m-a0">Basic Informations</h4>
              </div>
              <div className="panel-body wt-panel-body p-a20 m-b30 ">
                  <div className="row">
                    <div className="col-xl-4 col-lg-12 col-md-12">
                      <div className="form-group">
                        <label>Store Name</label>
                        <div className="ls-inputicon-box">
                          <input
                            onChange={(e) => SetstoreName(e.target.value)}
                            value={storeName}
                            className="form-control"
                            name="company_name"
                            type="text"
                            placeholder="Bila Bridals "
                          />
                          <i className="fs-input-icon fa fa-user "></i>
                        </div>
                        <div>
                          {err === true && ! storeName  ? (
                            <span style={{color:"red"}}>Enter store name</span>
                          ) : (
                            storeName === null
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-4 col-lg-12 col-md-12">
                      <div className="form-group">
                        <label>Phone</label>
                        <div className="ls-inputicon-box">
                          <input
                            onChange={(e) => setPhone(e.target.value)}
                            value={phone}
                            className="form-control"
                            name="company_phone"
                            type="text"
                            placeholder="(251) 1234-456-7890"
                          />
                          <i className="fs-input-icon fa fa-phone-alt"></i>
                        </div>
                        <div>
                          {err === true && ! phone   ? (
                            <span  style={{color:"red"}}>Enter phone number</span>
                          ) : (
                            phone  === null
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-4 col-lg-12 col-md-12">
                      <div className="form-group">
                        <label>Email Address</label>
                        <div className="ls-inputicon-box">
                          <input
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            className="form-control"
                            name="company_Email"
                            type="email"
                            placeholder="Devid@example.com"
                          />
                          <i className="fs-input-icon fa fa-envelope"></i>
                        </div>
                        <div>
                          {err === true && ! email  ? (
                            <span  style={{color:"red"}}>Enter email</span>
                          ) : (
                            email === null
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-4 col-lg-12 col-md-12">
                      <div className="form-group">
                        <label>Your Name</label>
                        <div className="ls-inputicon-box">
                          <input
                            onChange={(e) => setfullName(e.target.value)}
                            value={fullName}
                            className="form-control"
                            name="company_fullName"
                            type="text"
                            placeholder="David Johnson"  
                          />
                          <i className="fs-input-icon fa fa-user "></i>

                        </div>
                        <div>
                          {err === true && ! fullName  ? (
                            <span  style={{color:"red"}}>Enter fullName</span>
                          ) : (
                            fullName === null
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-4 col-lg-12 col-md-12">
                      <div className="form-group">
                        <label>Location</label>
                        <div className="ls-inputicon-box">
                          <input
                            onChange={(e) => setlocation(e.target.value)}
                            value={location}
                            className="form-control"
                            name="company_since"
                            type="text"
                            placeholder="Since..."
                          />
                          <i className="fs-input-icon fa fa-globe-americas"></i>
                        </div>
                        <div>
                          {err === true && ! location  ? (
                            <span  style={{color:"red"}}>Enter Location </span>
                          ) : (
                            location === null
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-4 col-lg-12 col-md-12">
                      <div className="form-group">
                        <label>Banner</label>
                        <div className="ls-inputicon-box">
                          <input
                            onChange={(e) => setLogo(e.target.files[0])}
                       
                            className="form-control"
                            name="company_since"
                            type="file"
                            placeholder="Banner"
                          />
                          <i className="fs-input-icon fa fa-globe-americas"></i>
                        </div>
                        <div>
                          {err === true && ! logo  ? (
                            <span  style={{color:"red"}}>Enter Banner </span>
                          ) : (
                            logo === null
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-4 col-lg-12 col-md-12">
                      <div className="form-group">
                        <label>Store Profile Photo</label>
                        <div className="ls-inputicon-box">
                          <input
                            onChange={(e) => setprofile_photo(e.target.files[0])}
                       
                            className="form-control"
                            name="company_since"
                            type="file"
                            placeholder="Banner"
                          />
                          <i className="fs-input-icon fa fa-globe-americas"></i>
                        </div>
                        <div>
                          {err === true && ! logo  ? (
                            <span  style={{color:"red"}}>Enter Profile Photo </span>
                          ) : (
                            logo === null
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-4 col-lg-12 col-md-12">
                      <div className="form-group">
                        <label>Password</label>
                        <div className="ls-inputicon-box">
                          <input
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                       
                            className="form-control"
                            name="company_since"
                            type="text"
                            placeholder="password"
                          />
                          <i className="fs-input-icon fa fa-globe-americas"></i>
                        </div>
                        <div>
                          {err === true && ! password  ? (
                            <span  style={{color:"red"}}>Enter password </span>
                          ) : (
                            location === null
                          )}
                        </div>
                      </div>
                    </div>

               


                    

                    
                

                    <div className="col-md-12">
                      <div className="form-group">
                        <label>about</label>
                        <textarea
                          onChange={(e) => setAbout(e.target.value)}
                          value={about}
                          className="form-control"
                          rows="3"
                          placeholder="Greetings! We are Galaxy Software Development Company."
                        ></textarea>
                      </div>
                      <div>
                          {err === true && ! about  ? (
                            <span  style={{color:"red"}}>Enter about</span>
                          ) : (
                            about === null
                          )}
                        </div>
                    </div>


                    <div className="col-lg-12 col-md-12">
                      <div className="text-left">
                        <button type="submit" className="site-button">
                            {loading? "Processing..." : "Create"}
                        </button>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
            </form>
            
          
          </div>
        </div>

        {/* <!--Delete Profile Popup--> */}
        <div
          className="modal fade twm-model-popup"
          id="delete-dash-profile"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <h4 className="modal-title">
                  Do you want to delete your profile?
                </h4>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="site-button"
                  data-bs-dismiss="modal"
                >
                  No
                </button>
                <button type="button" className="site-button outline-primary">
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* <!--Logout Profile Popup--> */}
        <div
          className="modal fade twm-model-popup"
          id="logout-dash-profile"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <h4 className="modal-title">
                  Do you want to Logout your profile?
                </h4>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="site-button"
                  data-bs-dismiss="modal"
                >
                  No
                </button>
                <button type="button" className="site-button outline-primary">
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- JAVASCRIPT  FILES ========================================= --> 
<script  src="js/jquery-3.6.0.min.js"></script><!-- JQUERY.MIN JS -->
<script  src="js/popper.min.js"></script><!-- POPPER.MIN JS -->
<script  src="js/bootstrap.min.js"></script><!-- BOOTSTRAP.MIN JS -->
<script  src="js/magnific-popup.min.js"></script><!-- MAGNIFIC-POPUP JS -->
<script  src="js/waypoints.min.js"></script><!-- WAYPOINTS JS -->
<script  src="js/counterup.min.js"></script><!-- COUNTERUP JS -->
<script  src="js/waypoints-sticky.min.js"></script><!-- STICKY HEADER -->
<script  src="js/isotope.pkgd.min.js"></script><!-- MASONRY  -->
<script  src="js/imagesloaded.pkgd.min.js"></script><!-- MASONRY  -->
<script  src="js/owl.carousel.min.js"></script><!-- OWL  SLIDER  -->
<script  src="js/theia-sticky-sidebar.js"></script><!-- STICKY SIDEBAR  -->
<script  src="js/lc_lightbox.lite.js" ></script><!-- IMAGE POPUP -->
<script  src="js/bootstrap-select.min.js"></script><!-- Form js -->
<script  src="js/dropzone.js"></script><!-- IMAGE UPLOAD  -->
<script  src="js/jquery.scrollbar.js"></script><!-- scroller -->
<script  src="js/bootstrap-datepicker.js"></script><!-- scroller -->
<script  src="js/jquery.dataTables.min.js"></script><!-- Datatable -->
<script  src="js/dataTables.bootstrap5.min.js"></script><!-- Datatable -->
<script  src="js/chart.js"></script><!-- Chart -->
<script  src="js/bootstrap-slider.min.js"></script><!-- Price range slider -->
<script  src="js/swiper-bundle.min.js"></script><!-- Swiper JS -->
<script  src="js/custom.js"></script><!-- CUSTOM FUCTIONS  -->
<script  src="js/switcher.js"></script><!-- SHORTCODE FUCTIONS  -->

<!-- STYLE SWITCHER  ======= --> 
<div className="styleswitcher">

    <div className="switcher-btn-bx">
        <a className="switch-btn">
            <span className="fa fa-cog fa-spin"></span>
        </a>
    </div> */}
      {/*     
    <div className="styleswitcher-inner">
    
        <h6 className="switcher-title">Color Skin</h6>
        <ul className="color-skins">
            <li><a className="theme-skin skin-1" href="dash-company-profilea39b.html?theme=css/skin/skin-1"></a></li>
            <li><a className="theme-skin skin-2" href="dash-company-profile61e7.html?theme=css/skin/skin-2"></a></li>
            <li><a className="theme-skin skin-3" href="dash-company-profilecce5.html?theme=css/skin/skin-3"></a></li>
            <li><a className="theme-skin skin-4" href="dash-company-profile13f7.html?theme=css/skin/skin-4"></a></li>
            <li><a className="theme-skin skin-5" href="dash-company-profile19a6.html?theme=css/skin/skin-5"></a></li>
            <li><a className="theme-skin skin-6" href="dash-company-profilefe5c.html?theme=css/skin/skin-6"></a></li>
            <li><a className="theme-skin skin-7" href="dash-company-profileab47.html?theme=css/skin/skin-7"></a></li>
            <li><a className="theme-skin skin-8" href="dash-company-profile5f8d.html?theme=css/skin/skin-8"></a></li>
            <li><a className="theme-skin skin-9" href="dash-company-profile5663.html?theme=css/skin/skin-9"></a></li>
            <li><a className="theme-skin skin-10" href="dash-company-profile28ac.html?theme=css/skin/skin-10"></a></li>
            
        </ul>           
        
    </div>    
</div>
<!-- STYLE SWITCHER END ==== --> */}
    </body>
  );
}
