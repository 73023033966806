import { Link } from "react-router-dom"
import pic1 from "../assets/images/images/jobs-company/pic1.jpg"
import pic2 from "../assets/images/images/jobs-company/pic2.jpg"
import pic3 from "../assets/images/images/jobs-company/pic3.jpg"
import pic4 from "../assets/images/images/jobs-company/pic4.jpg"
import pic5 from "../assets/images/images/jobs-company/pic5.jpg"
import logo from "../assets/cyberjet.png"
import GoogleTranslate from "../pages/store/GoogleTranslate"
import { useState } from "react"
// import logo_white from "../assets/images/images/logo-white.png"


export default function Dash_Header() {
    let adminData =JSON.parse(localStorage.getItem("smeemly-admin-user"))
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  }
    // console.log(adminData)
    return (
        <div>

               
                {/* <!-- Sidebar Holder --> */}
                <nav id="sidebar-admin-wraper">
                    <div className="page-logo">
                        <Link to="/">
                            <img src={logo} alt="" />
                        </Link>
                    </div>

                    <div className="admin-nav scrollbar-macosx">
                        <ul>
                            <li className="active">
                                <Link to="/dashboard">
                                    <i className="fa fa-home"></i>
                                    <span className="admin-nav-text">Dashboard</span>
                                </Link>
                            </li>

                            <li>
                                <Link to="/dash-company-profile">
                                    <i className="fa fa-user-tie"></i>
                                    <span className="admin-nav-text">Company Profile</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/dash-manage-product">
                                    <i className="fa fa-suitcase"></i>
                                    <span className="admin-nav-text">Manage Products</span>
                                </Link>
                            </li>
                             
                            <li>
                                <Link to="/dash-candidate">
                                    <i className="fa fa-user-friends"></i>
                                    <span className="admin-nav-text">Candidates</span>
                                </Link>
                            </li>

                           

                            <li>
                                <Link to="/dash-messages">
                                    <i className="fa fa-envelope"></i>
                                    <span className="admin-nav-text">Messages</span>
                                </Link>
                            </li>

                            <li>
                                <Link to="/dash-my-profile">
                                    <i className="fa fa-user"></i>
                                    <span className="admin-nav-text">My Profile</span>
                                </Link>
                            </li>

                            <li>
                                <Link to="/dash-change-password">
                                    <i className="fa fa-fingerprint"></i>
                                    <span className="admin-nav-text">Change Password</span>
                                </Link>
                            </li>

                            <li>
                                <Link to="/javascript:;"
                                    data-bs-toggle="modal"
                                    data-bs-target="#delete-dash-profile"
                                >
                                    <i className="fa fa-trash-alt"></i>
                                    <span className="admin-nav-text">Delete Profile</span>
                                </Link>
                            </li>

                            <li>
                                <Link to="/javascript:;"
                                    data-bs-toggle="modal"
                                    data-bs-target="#logout-dash-profile"
                                >
                                    <i className="fa fa-share-square"></i>
                                    <span className="admin-nav-text">Logout</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </nav>
                  {/* <!-- Sidebar Holder --> */}
                  <>
      <header className="top-nav">
        
        <div className="logo">
          <Link to="/"><img src={logo} alt="Logo" style={{height:"50px"}} /></Link>
        </div>
        <button className="hamburger" onClick={toggleSidebar}>
          ☰
        </button>
      </header>

      <nav
        id="sidebar-admin-wraper"
        className={isSidebarOpen ? "active" : ""}
      >
        <div className="page-logo">
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
        </div>
        <div className="admin-nav scrollbar-macosx res-js">
          <ul className="">
            <li>
              <Link to="/">
                <span className="admin-nav-text">Home</span>
              </Link>
            </li>
            <li>
              <Link to="/About">
                <span className="admin-nav-text">About Us</span>
              </Link>
            </li>
            <li>
              <Link to="/Service">
                <span className="admin-nav-text">Services</span>
              </Link>
            </li>
            <li>
              <Link to="/Testimonials">
                <span className="admin-nav-text">Testimonials</span>
              </Link>
            </li>
            <li>
              <Link to="/contact">
                <span className="admin-nav-text">Contact</span>
              </Link>
            </li>
            <li>
              <span className="admin-nav-text">
                <GoogleTranslate />
              </span>
            </li>
          </ul>
        </div>
      </nav>
    </>


             
        </div>
    )
}