import { Link, useNavigate } from "react-router-dom"

import { useEffect, useState } from "react"
// import { BASEURL } from "..././common/config"
import ComapnyNav from "../../components/CompanyNav"
import PublicHeader from "../../components/PublicHeader"
import axios from "axios"
import { BASEURL } from "../../common/config"


export default function StoreManageProduct() {
    const storeDetails = JSON.parse(localStorage.getItem("store-details"));
    // const comDetails = JSON.parse(localStorage.getItem("com-details"));
    const token = localStorage.getItem("store-token");
    // const navigate = useNavigate()
    // const [jobs, setJobs] = useState([])
    const [products, setProducts] = useState()
    useEffect(()=>{
    // console.log(token)

        axios.get(`${BASEURL}/store/${storeDetails._id}/products`)
        .then((res)=>{
            console.log(res.data.data)
            setProducts(res.data.data.reverse())
        }).catch((err)=>{
            console.log(err)
        })
    },[])


    const deleteData = (id) => {
   
        const shouldDelete = window.confirm("Are you sure you want to delete this product?");

        if (!shouldDelete) {
            return;
        }
        const headers = { Authorization: `Bearer ${token}`};
      

        let apiUrl = `${BASEURL}/product-delete/${id}`
        console.log(apiUrl)
        axios.delete(apiUrl, {headers})
        .then((res)=>{
          console.log(res)
          if(res.status && res.status === 200){
            alert(res.data.message)
            setProducts(prevProducts => prevProducts.filter(product => product._id != id));
          }else{
            alert("Could not create product")
          }
        })
        .catch((err)=>{
            console.log(err)
            if(err.response && err.response.data && err.response.data.message){
                alert(err.response.data.message)
            }else{
                alert("An error occurred, Please try again")
            }
        })
    }

    return (

        <div>
            {/* <!-- HEADER START --> */}
            <PublicHeader />
            {/* <!-- HEADER END --> */}
            {/* <!-- CONTENT START --> */}
            <div class="page-content">

                {/* <!-- OUR BLOG START --> */}
                <div class="section-full p-t120  p-b90 site-bg-white">


                    <div class="container">
                        <div class="row">

                            <div class="col-xl-3 col-lg-4 col-md-12 rightSidebar m-b30">
                                <ComapnyNav />
                            </div>

                            <div class="col-xl-9 col-lg-8 col-md-12 m-b30">
                                <div class="twm-right-section-panel candidate-save-job site-bg-gray">
                                    {/* <!--Filter Short By--> */}
                                    <div class="product-filter-wrap d-flex justify-content-between align-items-center">
                                        <span class="woocommerce-result-count-left"> {""} Products</span>
                                        <span class="woocommerce-result-count-right"> <Link to="/store-post-product" className="btn btn-primary">Create Product</Link></span>
                                    </div>

                                    <table id="" className="table table-bordered twm-bookmark-list-wrap">
                                        <thead>
                                            <tr>
                                                <th>Image</th>
                                                <th>Name</th>
                                                <th>Price</th>
                                                <th>Description</th>
                                                <th>Category</th>
                                                {/* <th>orders</th> */}
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* <!--1--> */}
                                            {
                                                products && products.map((prod) => (
                                                    <tr>

                                                        <td>
                                                            <figure style={{height:"150px", width:"150px", margin:"0", padding:"0"}}>
                                                                <img style={{height:"150px", width:"150px", objectFit:"contain",margin:"0", padding:"0"}} src={prod.photo} alt="" />
                                                            </figure>
                                                        </td>
                                                        <td>{prod.prodName}</td>
                                                        <td>{prod.price}</td>
                                                        <td>{prod.description}</td>
                                                        <td>
                                                           {prod.category_id.categoryName}
                                                        </td>

                                                        <td>
                                                            <div className="twm-table-controls">
                                                                <ul className="twm-DT-controls-icon list-unstyled">
                                                                    <li>
                                                                        <Link title="View profile" to={``} data-bs-toggle="tooltip" data-bs-placement="top">
                                                                            <span className="fa fa-eye"></span>
                                                                        </Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link to={`/store-edit-product/${prod._id}`} title="Edit" data-bs-toggle="tooltip" data-bs-placement="top">
                                                                            <span className="far fa-edit"></span>
                                                                        </Link>
                                                                    </li>
                                                                    <li>
                                                                        <button onClick={() => deleteData(prod._id)} title="Delete" data-bs-toggle="tooltip" data-bs-placement="top">
                                                                            <span className="far fa-trash-alt"></span>
                                                                        </button>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            }


                                        </tbody>
                                        <tfoot>
                                            <tr>
                                            <th>Image</th>
                                                <th>Name</th>
                                                <th>Price</th>
                                                <th>Description</th>
                                                <th>Category</th>
                                                {/* <th>orders</th> */}
                                                <th>Action</th>
                                            </tr>
                                        </tfoot>
                                    </table>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* <!-- OUR BLOG END --> */}



            </div>
            {/* <!-- CONTENT END --> */}
        </div>

    )
}