import { Link, Navigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { BASEURL } from "../../common/config";
import ComapnyNav from "../../components/CompanyNav";
import PublicHeader from "../../components/PublicHeader";
import axios from "axios";

export default function StoreEditProduct() {

  const storeDetails = JSON.parse(localStorage.getItem("store-details"));
  let storeId = storeDetails._id
  const token = localStorage.getItem("store-token");

  const [image, setImage] = useState();
  const [productName, setproductName] = useState();
  const [Category, setCategory] = useState();
  const [CategoryID, setCategoryId] = useState();
  const [price, setPrice] = useState();
  const [description, setDescription] = useState();
  const [newPhoto, setNewPhoto] = useState()
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false)

  const { prodId } = useParams()


  useEffect(()=>{
      axios.get(`${BASEURL}/product/${prodId}`)
      .then((res)=>{
          console.log(res.data.data)
          setproductName(res.data.data.prodName)
          setImage(res.data.data.photo)
          setPrice(res.data.data.price)
          setDescription(res.data.data.description)
          setCategoryId(res.data.data.category_id._id)
          // setCategory
      }).catch((err)=>{
          console.log(err)
      })
  },[])


  // Job category
  useEffect(() => {
    fetch(`${BASEURL}/category/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setCategory(data.data);
        console.log(data.data);
      })
      .catch((error) => {
        console.error("Fetch error:", error.message);
      });
  }, []);

  const uploadLogo = async (uploadImg) => {

    const data = new FormData();
    data.append("file", uploadImg);
    data.append( "upload_preset", process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET);
    data.append("cloud_name", process.env.REACT_APP_CLOUDINARY_CLOUD_NAME);
    data.append("folder", "Cloudinary-React");
    console.log(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/auto/upload`)
  
    try {
      const response = await fetch( `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/auto/upload`,
        {
          method: "POST",
          body: data,
        }
      );
      const res = await response.json();
      console.log(res)
     
    
      return res.secure_url
      
    } catch (error) {
  
      return null
      }
    };



  const handleCategory = (event) => {
    setCategoryId(event.target.options[event.target.selectedIndex].value);
  };

  const postJob = async (e) => {
    e.preventDefault();
    if (
      productName === undefined ||
      !CategoryID  ||
      description === undefined

    ) {
      alert("fill form ");
      setErr(true);
      return;
    }

   
    let imageUrl = await uploadLogo(newPhoto)

    setLoading(true)
    let productDetails = {
      store_id: storeId,
      prodName: productName,
      category_id: CategoryID,
      description:  description,
      price: price,
      photo: imageUrl
    }

    console.log(productDetails)

    let apiUrl = `${BASEURL}/product/update/${prodId}`
    console.log(apiUrl)
    let headers = {Authorization: `Bearer ${token}`}

    axios.put(apiUrl, productDetails, {headers})
    .then((res)=>{
      console.log(res)
      alert("product updated")
    })
    .catch((err)=>{
      console.log(err)
    })
    .finally(()=>{
      setLoading(false)
    })

  };

  return (
    <div>
      {/* <!-- HEADER START --> */}
      <PublicHeader />
      {/* <!-- HEADER END --> */}
      {/* <!-- CONTENT START --> */}
      <div class="page-content">

        {/* <!-- OUR BLOG START --> */}
        <div class="section-full p-t120  p-b90 site-bg-white">


          <div class="container">
            <div class="row">

              <div class="col-xl-3 col-lg-4 col-md-12 rightSidebar m-b30">
                <ComapnyNav />
              </div>

              <div class="col-xl-9 col-lg-8 col-md-12 m-b30">
                <div class="twm-right-section-panel candidate-save-job site-bg-gray">
                  {/* <!--Filter Short By--> */}
                  {/* <!--Basic Information--> */}
                  <div className="panel panel-default">
                    <div className="panel-heading wt-panel-heading p-a20">
                      <h4 className="panel-tittle m-a0">
                        <i className="fa fa-suitcase"></i>Edit Product
                      </h4>
                    </div>
                      <div className="panel panel-default">
              <div className="panel-heading wt-panel-heading p-a20">
              </div>
              <div className="col-lg-12 col-md-12 ">
                <div className="dashboard-cover-pic">
                  <div
                    action="https://thewebmax.org/jobzilla/upload.php"
                    className="dropzone "
                  >
                    <div className="row mb-5">
                      <div className="col-lg-6 ">
                        <label className="mb-3 fw-bolder" htmlFor="">Product photo</label>
                        {/* <input type="text" placeholder="input company logo" onChange={(e) => setprofile_photo(e.target.value)} className="form-control" /> */}

                        <figure className="h-75 w-100 ">
                          <img src={image} alt="" className="h-100 w-100" />
                        </figure>
                      </div>
                      
                    </div>
                  </div>

                </div>
              </div>
            </div>
                    <div className="panel-body wt-panel-body p-a20 m-b30 ">
                      <form onSubmit={postJob}>
                        <div className="row">
                          {/* <!--Job title-->             */}
                          <div className="col-xl-4 col-lg-6 col-md-12">
                            <div className="form-group">
                              <label>Product Name</label>
                              <div className="ls-inputicon-box">
                                <input
                                  className="form-control"
                                  name="company_name"
                                  type="text"
                                  placeholder="Bag"
                                  onChange={(e) => setproductName(e.target.value)}
                                  value={productName}
                                />
                                <i className="fs-input-icon fa fa-address-card"></i>
                              </div>
                              <div>
                                {err === true && productName === undefined ? (
                                  <span>Enter Product Name</span>
                                ) : (
                                  productName === null
                                )}
                              </div>
                            </div>
                          </div>

                          {/* <!--Job Category-->  */}
                          <div className="col-xl-4 col-lg-6 col-md-12">
                            <div className="form-group city-outer-bx has-feedback">
                              <label>Category</label>
                              <div className="ls-inputicon-box ">
                                <select
                                  className="wt-select-box selectpicker form-select form-select-lg mb-3"
                                  data-live-search="true"
                                  title=""
                                  id="j-category"
                                  data-bv-field="size"
                                  onChange={handleCategory}
                                  value={CategoryID}
                                >
                                  <option value="">Select Category</option>
                                  {Category &&
                                    Category.map((cat) => (
                                      <option key={cat._id} value={cat._id}>
                                        {cat.categoryName}
                                      </option>
                                    ))}
                                </select>

                                <i className="fs-input-icon fa fa-border-all"></i>
                              </div>
                              <div>
                                {err === true && !CategoryID ? (
                                  <span>Enter job category</span>
                                ) : (
                                  Category === null
                                )}
                              </div>
                            </div>
                          </div>


                          <div className="col-xl-4 col-lg-6 col-md-12">
                            <div className="form-group">
                              <label>Price</label>
                              <div className="ls-inputicon-box">
                                <input
                                  className="form-control"
                                  name="company_name"
                                  type="number"
                                  placeholder="Devid Smith"
                                  onChange={(e) => setPrice(e.target.value)}
                                  value={price}
                                />
                                <i className="fs-input-icon fa fa-address-card"></i>
                              </div>
                              <div>
                                {err === true && price === undefined ? (
                                  <span>Enter price</span>
                                ) : (
                                  price === null
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="col-xl-4 col-lg-6 col-md-12">
                            <div className="form-group">
                              <label>product Image</label>
                              <div className="ls-inputicon-box">
                                <input
                                  className="form-control"
                                  name="company_name"
                                  type="file"
                                  placeholder="Devid Smith"
                                  onChange={(e) => setNewPhoto(e.target.files[0])}
                                
                                />
                                <i className="fs-input-icon fa fa-address-card"></i>
                              </div>
                              <div>
                              {err === true && !image && <span>Enter image</span>}
                              </div>
                            </div>
                          </div>
                      




                          {/* <!--Description--> */}
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Description</label>
                              <textarea
                                className="form-control"
                                rows="3"
                                placeholder="write a brief description about this product"
                                onChange={(e) => setDescription(e.target.value)}
                                value={description}
                              ></textarea>
                            </div>
                            <div>
                              {err === true && description === undefined ? (
                                <span>Enter description</span>
                              ) : (
                                description === null
                              )}
                            </div>
                          </div>
                      



                          <div className="col-lg-12 col-md-12">
                            <div className="text-left">
                              <button type="submit" className="site-button m-r5">
                               {loading ? "updating..." : "Update"}
                              </button>
                              {/* <button
                          type="submit"
                          className="site-button outline-primary"
                        >
                          Save Draft
                        </button> */}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>


                </div>
              </div>

            </div>
          </div>
        </div>
        {/* <!-- OUR BLOG END --> */}



      </div>
      {/* <!-- CONTENT END --> */}
    </div>
  )


}
