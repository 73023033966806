
import logo_11 from "../assets/icons/5amas-logo.svg";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from "react-router-dom"
import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASEURL } from "../common/config";
import { useParams } from "react-router-dom";




export default function PublicHeader() {
    const storeDetails = JSON.parse(localStorage.getItem("store-details"));
    // const comDetails = JSON.parse(localStorage.getItem("com-details"));
    const token = localStorage.getItem("store-token");
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <div>
            {/* HEADERR START */}
            <header className="site-header header-style-3 h-page-11-hdr mobile-sider-drawer-menu">
                <div className="sticky-header main-bar-wraper navbar-expand-lg">
                    <div className="main-bar">
                        <div className="container-fluid clearfix">
                            <div className="logo-header">
                                <div className="logo-header-inner logo-header-one">
                                    <Link to="/store-dashboard">
                                        <img src={logo_11} alt="" />
                                    </Link>
                                </div>
                            </div>

                            {/* NAV Toggle Button */}
                            <button
                                id="mobile-side-drawer"
                                data-target=".header-nav"
                                data-toggle="collapse"
                                type="button"
                                className="navbar-toggler collapsed"
                            >
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar icon-bar-first"></span>
                                <span className="icon-bar icon-bar-two"></span>
                                <span className="icon-bar icon-bar-three"></span>
                            </button>

                            {/* MAIN Nav */}
                            <div className="nav-animation header-nav navbar-collapse collapse d-flex justify-content-center">
                                <ul className="nav navbar-nav">
                    <li class="active"><Link to="/store-dashboard">Dashboard</Link></li>
                    <li><Link to="/com-profile">My Profile</Link></li>
                    <li><Link to="/store-manager-product"> Manage products</Link></li>
                    <li><Link to="/store-orders">Orders</Link></li>
                    {/* <li><Link to="candidate-chat.html"><i class="fa fa-comments"></i>Messages</Link></li> */}
                    {/* <li><Link to="candidate-change-password.html"><i class="fa fa-fingerprint"></i> Change Password</Link></li> */}
                    <li>
                        <Link to="/javascript:0;"
                            data-bs-toggle="modal"
                            data-bs-target="#logout-dash-profile"
                        >
                            <i className="fa fa-share-square"></i>
                            <span className="admin-nav-text">Logout</span>
                        </Link>
                    </li>
                                </ul>
                            </div>

                            {/* Header Right Section */}
                            <div className="extra-nav header-2-nav">
                                <div className="extra-cell">
                                    <div className="header-search">
                                        <Link to="/#search" className="header-search-icon">
                                            <i className="feather-search"></i>
                                        </Link>
                                    </div>
                                </div>
                                <div className="extra-cell">
                                    <div className="header-nav-btn-section">
                                        <div className="twm-nav-btn-left">
                                            {token == null ? <a
                                                className="twm-nav-sign-up"
                                                data-bs-toggle="modal"
                                                href="#sign_up_popup"
                                                role="button"
                                            >
                                                <i className="feather-log-in"></i> Sign Up
                                            </a> : <Link
                                                className="twm-nav-sign-up"
                                                
                                                to= {storeDetails.role === "store" ?"/store-profile":"/my-dashboard"} 
                                                role="button"
                                            >
                                                <i className="feather-user"></i> {storeDetails.role === "store" ?<> Store Profile</>: null}  
                                            </Link>}

                                        </div>
                                        {storeDetails != null && storeDetails.role === "store" ? <div className=" link-tag twm-nav-btn-right">
                                            <Link to="/store-post-product" className=" link-tag twm-nav-post-a-job">
                                                <i className=" link-tag feather-briefcase"></i> Post product
                                            </Link>
                                        </div> : <></>}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* SITE Search */}
                    <div id="search">
                        <span className="close"></span>
                        <form
                            role="search"
                            id="searchform"
                            action="https://thewebmax.org/search"
                            method="get"
                            className="radius-xl"
                        >
                            <input
                                className="form-control"
                                value=""
                                name="q"
                                type="search"
                                placeholder="Type to search"
                            />
                            <span className="input-group-append">
                                <button type="button" className="search-btn">
                                    <i className="fa fa-paper-plane"></i>
                                </button>
                            </span>
                        </form>
                    </div>
                </div>
            </header>
            {/* HEADER END  */}
        </div>

    )
}