import Dash_Header from "../../components/Dashheader";
import Footer from "../../components/Footer";
function Service() {
  return (
    <>
      <div className="page-wraper">
        <Dash_Header />

        <div id="content">
          <div
            style={{
              backgroundColor: "white",
              color: "#0b0077",
              padding: "60px 0px",
            }}
          >
            {/* Heading */}
            <div className="text-center mb-5 mt-5">
              <h1 style={{ fontSize: "3rem", fontWeight: "bold" }}>Services</h1>
            </div>

            {/* Cards */}
            <div
              className="d-flex flex-wrap justify-content-center align-items-stretch gap-4 mb-5 text-center"
              style={{ padding: "0 15px" }}
            >
              {/* Card 1 */}
              <div
                className="card"
                style={{
                  flex: "1 1 calc(30% - 1rem)",
                  minWidth: "280px",
                  maxWidth: "30%",
                  backgroundColor: "white",
                  color: "black",
                  border: "none",
                }}
              >
                <img
                  src="https://1ff354f48f.clvaw-cdnwnd.com/aebbcd855c8771ef42699f56766d3f68/200000003-ec151ec152/images-pexels-com-photos-2977547-pexels-photo-2977547-auto-compress-cs-tinysrgb-dpr-2-h-650-w-940.jpeg?ph=1ff354f48f"
                  className="card-img-top"
                  alt="Service 1"
                  style={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "10px 10px 0 0",
                  }}
                />
                <div className="card-body">
                  <h5
                    className="card-title"
                    style={{
                      marginTop: "20px",
                      fontSize: "1.5rem",
                      fontWeight: "normal",
                    }}
                  >
                    Digital evidence collection and preservation
                  </h5>
                  <p
                    className="card-text"
                    style={{
                      fontSize: "1.25rem",
                      lineHeight: "1.4",
                      marginTop: "15px",
                    }}
                  >
                    Digital evidence collection and preservation involves the
                    careful and systematic gathering, documenting, and storing
                    of electronic data to ensure its authenticity and
                    admissibility in court.
                  </p>
                </div>
              </div>

              {/* Card 2 */}
              <div
                className="card"
                style={{
                  flex: "1 1 calc(30% - 1rem)",
                  minWidth: "280px",
                  maxWidth: "30%",
                  backgroundColor: "white",
                  color: "black",
                  border: "none",
                }}
              >
                <img
                  src="https://1ff354f48f.clvaw-cdnwnd.com/aebbcd855c8771ef42699f56766d3f68/200000001-e3635e3636/images-pexels-com-photos-3760067-pexels-photo-3760067-auto-compress-cs-tinysrgb-dpr-2-h-650-w-940.jpeg?ph=1ff354f48f"
                  className="card-img-top"
                  alt="Service 2"
                  style={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "10px 10px 0 0",
                  }}
                />
                <div className="card-body">
                  <h5
                    className="card-title"
                    style={{
                      marginTop: "20px",
                      fontSize: "1.5rem",
                      fontWeight: "normal",
                    }}
                  >
                    Data recovery and analysis
                  </h5>
                  <p
                    className="card-text"
                    style={{
                      fontSize: "1.25rem",
                      lineHeight: "1.4",
                      marginTop: "15px",
                    }}
                  >
                    Data recovery and analysis is the process of retrieving lost
                    or corrupted data and examining it to gain insights and make
                    informed decisions.
                  </p>
                </div>
              </div>

              {/* Card 3 */}
              <div
                className="card"
                style={{
                  flex: "1 1 calc(30% - 1rem)",
                  minWidth: "280px",
                  maxWidth: "30%",
                  backgroundColor: "white",
                  color: "black",
                  border: "none",
                }}
              >
                <img
                  src="https://1ff354f48f.clvaw-cdnwnd.com/aebbcd855c8771ef42699f56766d3f68/200000002-8c97d8c97e/images-pexels-com-photos-4101143-pexels-photo-4101143-auto-compress-cs-tinysrgb-dpr-2-h-650-w-940.jpeg?ph=1ff354f48f"
                  className="card-img-top"
                  alt="Service 3"
                  style={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "10px 10px 0 0",
                  }}
                />
                <div className="card-body">
                  <h5
                    className="card-title"
                    style={{
                      marginTop: "20px",
                      fontSize: "1.5rem",
                      fontWeight: "normal",
                    }}
                  >
                    Forensic examination of electronic devices and networks
                  </h5>
                  <p
                    className="card-text"
                    style={{
                      fontSize: "1.25rem",
                      lineHeight: "1.4",
                      marginTop: "15px",
                    }}
                  >
                    Forensic examination of electronic devices and networks
                    involves the analysis and collection of digital evidence to
                    uncover information related to a crime or security breach.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="d-flex flex-wrap justify-content-center align-items-stretch gap-4 mb-5 text-center mt-5"
              style={{ padding: "0 15px" }}
            >
              {/* Card 1 */}
              <div
                className="card"
                style={{
                  flex: "1 1 calc(30% - 1rem)",
                  minWidth: "280px",
                  maxWidth: "30%",
                  backgroundColor: "white",
                  color: "black",
                  border: "none",
                }}
              >
                <img
                  src="https://1ff354f48f.clvaw-cdnwnd.com/aebbcd855c8771ef42699f56766d3f68/200000020-7615a7615c/images-pexels-com-photos-4458554-pexels-photo-4458554-auto-compress-cs-tinysrgb-dpr-2-h-650-w-940.webp?ph=1ff354f48f"
                  className="card-img-top"
                  alt="Service 1"
                  style={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "10px 10px 0 0",
                  }}
                />
                <div className="card-body">
                  <h5
                    className="card-title"
                    style={{
                      marginTop: "20px",
                      fontSize: "1.5rem",
                      fontWeight: "normal",
                    }}
                  >
                    Network and system vulnerability assessments
                  </h5>
                  <p
                    className="card-text"
                    style={{
                      fontSize: "1.25rem",
                      lineHeight: "1.4",
                      marginTop: "15px",
                    }}
                  >
                    Network and system vulnerability assessments are crucial
                    processes for identifying potential security risks and
                    weaknesses in a company's network and computer systems.
                  </p>
                </div>
              </div>

              {/* Card 2 */}
              <div
                className="card"
                style={{
                  flex: "1 1 calc(30% - 1rem)",
                  minWidth: "280px",
                  maxWidth: "30%",
                  backgroundColor: "white",
                  color: "black",
                  border: "none",
                }}
              >
                <img
                  src="https://1ff354f48f.clvaw-cdnwnd.com/aebbcd855c8771ef42699f56766d3f68/200000017-a1d08a1d0a/images-pexels-com-photos-5699480-pexels-photo-5699480-auto-compress-cs-tinysrgb-dpr-2-h-650-w-940.webp?ph=1ff354f48f"
                  className="card-img-top"
                  alt="Service 2"
                  style={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "10px 10px 0 0",
                  }}
                />
                <div className="card-body">
                  <h5
                    className="card-title"
                    style={{
                      marginTop: "20px",
                      fontSize: "1.5rem",
                      fontWeight: "normal",
                    }}
                  >
                    Incident response and remediation
                  </h5>
                  <p
                    className="card-text"
                    style={{
                      fontSize: "1.25rem",
                      lineHeight: "1.4",
                      marginTop: "15px",
                    }}
                  >
                    Incident response and remediation involve timely and
                    effective actions taken to address and mitigate security
                    breaches or cyber attacks.
                  </p>
                </div>
              </div>

              {/* Card 3 */}
              <div
                className="card"
                style={{
                  flex: "1 1 calc(30% - 1rem)",
                  minWidth: "280px",
                  maxWidth: "30%",
                  backgroundColor: "white",
                  color: "black",
                  border: "none",
                }}
              >
                <img
                  src="https://1ff354f48f.clvaw-cdnwnd.com/aebbcd855c8771ef42699f56766d3f68/200000011-e0e2ce0e2e/images-pexels-com-photos-4100672-pexels-photo-4100672-auto-compress-cs-tinysrgb-dpr-2-h-650-w-940.webp?ph=1ff354f48f"
                  className="card-img-top"
                  alt="Service 3"
                  style={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "10px 10px 0 0",
                  }}
                />
                <div className="card-body">
                  <h5
                    className="card-title"
                    style={{
                      marginTop: "20px",
                      fontSize: "1.5rem",
                      fontWeight: "normal",
                    }}
                  >
                    Expert witness testimony and litigation support
                  </h5>
                  <p
                    className="card-text"
                    style={{
                      fontSize: "1.25rem",
                      lineHeight: "1.4",
                      marginTop: "15px",
                    }}
                  >
                    Expert witness testimony and litigation support provide
                    crucial insight and information to help resolve legal
                    disputes and ensure fair and just outcomes in court.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Footer/>
        </div>
      </div>
    </>
  );
}
export default Service;
