import { Link, useNavigate } from "react-router-dom"
import Dash_Header from "../components/Dashheader"
import { useEffect, useState } from "react"
import { BASEURL } from "../common/config"
import axios from "axios"

export default function DashManageUsers(){
    
    const [users, setUsers] = useState([]); // Initialize with an empty array to avoid undefined errors
    const navigate = useNavigate();
    let adminToken = localStorage.getItem("smeemly-admin-token");

    useEffect(() => {
        fetch(`${BASEURL}/user/all`, {
          method: 'GET', 
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${adminToken}` // Use token for protected routes
          },
        })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json(); 
        })
        .then(data => {
          console.log(data);
          setUsers(data); // Update the state with the array of users
        })
        .catch(error => {
          console.error('Fetch error:', error.message);
        });
      }, [adminToken]); // Add `adminToken` as a dependency

    const deleteData = async (id) => {
        const shouldDelete = window.confirm("Are you sure you want to delete this user?");
        if (shouldDelete) {
            let apiURL = `${BASEURL}/user/delete/${id}`;
            let headers = {
                "Authorization": `Bearer ${adminToken}`
            };
            try {
                const res = await axios.delete(apiURL, { headers });
                console.log(res.data);
                alert("User deleted");
                setUsers((prev) => prev.filter(user => user._id !== id));
            } catch (err) {
                console.log(err);
            }
        }
    }

    return (
        <div className="page-wraper">
            <Dash_Header />
            <div id="content">
                <div className="content-admin-main">
                    <div className="wt-admin-right-page-header clearfix">
                        <h2>Users</h2>
                        <div className="breadcrumbs">
                            <a href="#">Home</a><a href="#">Dashboard</a><span>Users</span>
                        </div>
                    </div>

                    <div className="twm-pro-view-chart-wrap">
                        <div className="col-lg-12 col-md-12 mb-4">
                            <div className="panel panel-default site-bg-white m-t30">
                                <div className="panel-heading wt-panel-heading p-a20">
                                    <h4 className="panel-tittle m-a0"><i className="far fa-list-alt"></i> All Users</h4>
                                    <h5>Users ({Array.isArray(users) ? users.length : 0})</h5> {/* Safe length access */}
                                </div>
                                <div className="panel-body wt-panel-body">
                                    <div className="twm-D_table p-a20 table-responsive">
                                        <table id="users_data_table" className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Order</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Array.isArray(users) && users.length > 0 ? (
                                                    users.map((user) => (
                                                        <tr key={user._id}>
                                                            <td>{user.firstName} {user.lastName}</td>
                                                            <td>{user.email}</td>
                                                            <td>{user.order_id ? user.order_id.length : 0}</td> {/* Safe access */}
                                                            <td>
                                                                <div className="twm-table-controls">
                                                                    <ul className="twm-DT-controls-icon list-unstyled">
                                                                        <li>
                                                                            <button onClick={() => deleteData(user._id)} title="Delete" data-bs-toggle="tooltip" data-bs-placement="top">
                                                                                <span className="far fa-trash-alt"></span>
                                                                            </button>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="5">No users found</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Order</th>
                                                    <th>Action</th>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                              
                </div>
            </div>
        </div>
    );
}
