// import logo from './logo.svg';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Index from './pages';
import JobList from './pages/job-list';
import EmployerList from './pages/employer-list';
import About from './pages/about';
import Contact from './pages/contact';
import DashBookmark from './pages/dash-bookmark';
import DashCandidates from './pages/dash-manage-users';
import DashChangePassword from './pages/dash-chnage-password';
import DashCompanyProfile from './pages/dash-company-profile';
import DashCreateCompany from './pages/dash-create-store';
import DashManageJob from './pages/dash-manage-product';
import DashManageCompany from './pages/dash-manage-store';
import DashMessages from './pages/dash-messages';
import DashMyProfile from './pages/dash-my-profile';
import DashPostJob from './pages/dash-post-product';
import Dashboard from './pages/dashboard';
import EmployerDetail from './pages/employer-detail';
import JobDetail from './pages/job-detail';
import EditCompany from './pages/dash-edit-store';
// import EditJob from './pages/dash-edit-job';
import CreateUser from './pages/dash-create-user';
import ManageUsers from './pages/dash-manage-users';
import EditUser from './pages/dash-edit-user';
import ApplyJob from './pages/dash-apply-job';
import UserDashboard from './pages/candidate/user-dashboard';
import UserProfile from './pages/candidate/user-profile';
import UserResume from './pages/candidate/user-resume';
import Dash_Header from './components/Dashheader';
import UserApply from './pages/candidate/user-apply';
import CompanyProfile from './pages/store/store-profile';
import CompanyResume from './pages/store/company-resume';
import CompanyApply from './pages/store/company-apply';
import CompanyPostJob from './pages/store/store-post-product';
import CompanyEditJob from './pages/store/store-edit-product';
import CompanyManageJob from './pages/store/store-manage-product';
import ViewCandidate from './pages/view-candidate';
import UserChat from './pages/candidate/user-chat';
import CreateAticle from './pages/candidate/create-article';
import UserArticle from './pages/candidate/user-article';
import UserEditAticle from './pages/candidate/user-edit-article';
import Blog from './pages/Blog';
import BlogSingle from './pages/blog-single';
import Job_Category from './pages/dash-manage-category';
import DashPostJobCategory from './pages/dash-post-category';
import DashEditJobCategory from './pages/dash-edit-job-category';
import VerifyAdmin from './pages/verify-admin';
import Searched_JobList from './pages/searched-job';
import StoreDashboard from './pages/store/store-dashboard';
import Login from './components/sign-in';
import StoreManageProduct from './pages/store/store-manage-product';
import StorePostProduct from './pages/store/store-post-product';
import StoreEditProduct from './pages/store/store-edit-product';
import StoreOrders from './pages/store/store-orders';
import DashManageStore from './pages/dash-manage-store';
import CreateStore from './pages/dash-create-store';
import EditStore from './pages/dash-edit-store';
import DashManageProduct from './pages/dash-manage-product';
import DashPostProduct from './pages/dash-post-product';
import DashEditProduct from './pages/dash-edit-product';
import Category from './pages/dash-manage-category';
import DashManageCategory from './pages/dash-manage-category';
import DashPostCategory from './pages/dash-post-category';
import DashUsers from './pages/dash-manage-users';
import DashManageUsers from './pages/dash-manage-users';
import StoreProfile from './pages/store/store-profile';
import StoreEditProfile from './pages/store/store-edit-profile';
import Home from './pages/Home';
import Service from './pages/store/Service';
import Testimonials from './pages/store/Testimonials';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
        



          <Route path='/Service' element={<Service/>}/> 
          <Route path='/Testimonials' element={<Testimonials/>}/> 
          <Route path='/store-profile' element={<StoreProfile/>}/> 
          <Route path='/store-edit-profile' element={<StoreEditProfile/>}/> 
          <Route path='/com-resume' element={<CompanyResume/>}/> 
          <Route path='/com-application' element={<CompanyApply/>}/> 
          <Route path='/store-manager-product' element={<StoreManageProduct/>}/> 
          <Route path='/store-post-product' element={<StorePostProduct/>}/> 
          <Route path='/store-edit-product/:prodId' element={<StoreEditProduct/>}/> 
          <Route path='/store-orders' element={<StoreOrders/>}/> 
          

          
          
          <Route path='/contact' element={<Contact/>}/>
          <Route path='/dash-manage-store' element={<DashManageStore/>}/>
          <Route path='/dash-create-store' element={<CreateStore/>}/>
          <Route path='/edit-store/:storeId' element={<EditStore/>}/>
          <Route path='/dash-manage-product' element={<DashManageProduct/>}/>
          <Route path='/dash-post-product' element={<DashPostProduct/>}/>
          <Route path='/dash-edit-product/:_id' element={<DashEditProduct/>}/>
          <Route path='/dash-category' element={<DashManageCategory/>}/>
          <Route path='/dash-post-job-category' element={<DashPostCategory/>}/>
          <Route path='/dash-edit-category/:CategoryId' element={<DashEditJobCategory/>}/>
          <Route path='/dash-manage-users' element={<DashManageUsers/>}/>
          <Route path='/About' element={<About/>}/>
          <Route path='/' element={<Home/>}/>


          <Route path='/dash-bookmark' element={<DashBookmark/>}/>
          <Route path='/dash-change-password' element={<DashChangePassword/>}/>
          <Route path='/dash-company-profile' element={<DashCompanyProfile/>}/>
          <Route path='/dash-messages' element={<DashMessages/>}/>
          <Route path='/dash-my-profile' element={<DashMyProfile/>}/>
          <Route path='/dash-create-user' element={<CreateUser/>}/>
          {/* <Route path='/dash-manage-user' element={<ManageUsers/>}/> */}
          <Route path='/dash-edit-user/:userId' element={<EditUser/>}/>
          <Route path='/dash-apply' element={<ApplyJob/>}/>
          
         
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
