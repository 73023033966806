import Dashheader from "../components/Dashheader";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";

function Home() {
  return (
    <>
      <div className="page-wraper">
        <Dashheader />

        <div id="content">
          <div
            className="d-flex align-items-center justify-content-center text-center bg-image"
            style={{
              height: "100vh",
              backgroundImage: `
                linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
                url('https://1ff354f48f.clvaw-cdnwnd.com/aebbcd855c8771ef42699f56766d3f68/200000006-452e9452ea/images-pexels-com-photos-3760093-pexels-photo-3760093-auto-compress-cs-tinysrgb-dpr-2-h-650-w-940.jpeg?ph=1ff354f48f')
              `,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              color: "white",
            }}
          >
            <div>
              <h1 className="fw-bold">Cyber Jet Hub</h1>
              <h2 className="mt-3">
                "Uncovering the truth, one byte at a time."
              </h2>
            </div>
          </div>
          {/* About Us Section */}
          <div
            style={{
              background: "",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              color: "#0b0077",
              padding: "130px 50px",
            }}
          >
            <div className="text-center mb-5">
              <h1 style={{ fontSize: "2.5rem", fontWeight: "bold" }}>
                About Us
              </h1>
            </div>

            <div className="d-flex flex-column flex-lg-row align-items-center">
              {/* Image on the Left */}
              <div className="mb-4 mb-lg-0" style={{ flex: "1 1 50%" }}>
                <img
                  src="https://1ff354f48f.clvaw-cdnwnd.com/aebbcd855c8771ef42699f56766d3f68/200000004-197a8197aa/images-pexels-com-photos-4100672-pexels-photo-4100672-auto-compress-cs-tinysrgb-dpr-2-h-650-w-940.jpeg?ph=1ff354f48f"
                  alt="About Us"
                  style={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "15px",
                  }}
                />
              </div>

              {/* Text on the Right */}
              <div
                className="ms-lg-5"
                style={{ flex: "1 1 50%", color: "black" }}
              >
                <p style={{ fontSize: "1.2rem", lineHeight: "1.3" }}>
                  <span style={{ fontWeight: "bold" }}>At Cyber Jet Hub</span>,
                  our mission is to combat cybercrime by providing expert
                  digital forensics services. We understand the growing threat
                  of cybercrime and the need for reliable and thorough
                  investigations to protect individuals and organizations. Our
                  team consists of highly trained and experienced professionals
                  who use the latest technology and techniques to collect,
                  analyze, and preserve electronic evidence. We work with law
                  enforcement agencies, corporations, and individuals to
                  investigate cyber attacks, data breaches, fraud, and other
                  digital crimes.
                </p>
                <p style={{ fontSize: "1.2rem", lineHeight: "1.3" }}>
                  Crypto investigation, also known as cryptocurrency
                  investigation or digital currency investigation, refers to the
                  process of examining and analyzing activities related to
                  cryptocurrencies. Cryptocurrencies, such as Bitcoin, Ethereum,
                  and others, operate on decentralized blockchain technology,
                  providing a level of anonymity and privacy for users. This has
                  led to increased interest in investigating various illicit
                  activities and crimes involving cryptocurrencies.
                </p>
              </div>
            </div>
            <div className="text-center">
              <button
                style={{
                  color: "#0b0077",
                  borderColor: "#0b0077",
                  marginTop: "50px",
                }}
                className="btn btn-outline-light btn-lg"
              >
                <Link style={{ color: "#0b0077" }} to="/about">
                  Read More
                </Link>
              </button>
            </div>
          </div>

          {/* Services Section */}
          <div
            style={{
              backgroundColor: "white",
              color: "#0b0077",
              padding: "60px 0px",
            }}
          >
            {/* Heading */}
            <div className="text-center mb-5">
              <h1 style={{ fontSize: "2.5rem", fontWeight: "bold" }}>
                Services
              </h1>
            </div>

            {/* Cards */}
            <div
              className="d-flex flex-wrap justify-content-center align-items-stretch gap-4 mb-5 text-center"
              style={{ padding: "0 15px" }}
            >
              {/* Card 1 */}
              <div
                className="card"
                style={{
                  flex: "1 1 calc(30% - 1rem)",
                  minWidth: "280px",
                  maxWidth: "30%",
                  backgroundColor: "white",
                  color: "black",
                  border: "none",
                }}
              >
                <img
                  src="https://1ff354f48f.clvaw-cdnwnd.com/aebbcd855c8771ef42699f56766d3f68/200000003-ec151ec152/images-pexels-com-photos-2977547-pexels-photo-2977547-auto-compress-cs-tinysrgb-dpr-2-h-650-w-940.jpeg?ph=1ff354f48f"
                  className="card-img-top"
                  alt="Service 1"
                  style={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "10px 10px 0 0",
                  }}
                />
                <div className="card-body">
                  <h5
                    className="card-title"
                    style={{
                      marginTop: "20px",
                      fontSize: "1.5rem",
                      fontWeight: "normal",
                      color: "black",
                    }}
                  >
                    Digital evidence collection and preservation
                  </h5>
                  <p
                    className="card-text"
                    style={{
                      fontSize: "1.25rem",
                      lineHeight: "1.4",
                      marginTop: "15px",
                    }}
                  >
                    Digital evidence collection and preservation involves the
                    careful and systematic gathering, documenting, and storing
                    of electronic data to ensure its authenticity and
                    admissibility in court.
                  </p>
                </div>
              </div>

              {/* Card 2 */}
              <div
                className="card"
                style={{
                  flex: "1 1 calc(30% - 1rem)",
                  minWidth: "280px",
                  maxWidth: "30%",
                  backgroundColor: "white",
                  color: "black",
                  border: "none",
                }}
              >
                <img
                  src="https://1ff354f48f.clvaw-cdnwnd.com/aebbcd855c8771ef42699f56766d3f68/200000001-e3635e3636/images-pexels-com-photos-3760067-pexels-photo-3760067-auto-compress-cs-tinysrgb-dpr-2-h-650-w-940.jpeg?ph=1ff354f48f"
                  className="card-img-top"
                  alt="Service 2"
                  style={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "10px 10px 0 0",
                  }}
                />
                <div className="card-body">
                  <h5
                    className="card-title"
                    style={{
                      marginTop: "20px",
                      fontSize: "1.5rem",
                      fontWeight: "normal",
                    }}
                  >
                    Data recovery and analysis
                  </h5>
                  <p
                    className="card-text"
                    style={{
                      fontSize: "1.25rem",
                      lineHeight: "1.4",
                      marginTop: "15px",
                    }}
                  >
                    Data recovery and analysis is the process of retrieving lost
                    or corrupted data and examining it to gain insights and make
                    informed decisions.
                  </p>
                </div>
              </div>

              {/* Card 3 */}
              <div
                className="card"
                style={{
                  flex: "1 1 calc(30% - 1rem)",
                  minWidth: "280px",
                  maxWidth: "30%",
                  backgroundColor: "white",
                  color: "black",
                  border: "none",
                }}
              >
                <img
                  src="https://1ff354f48f.clvaw-cdnwnd.com/aebbcd855c8771ef42699f56766d3f68/200000002-8c97d8c97e/images-pexels-com-photos-4101143-pexels-photo-4101143-auto-compress-cs-tinysrgb-dpr-2-h-650-w-940.jpeg?ph=1ff354f48f"
                  className="card-img-top"
                  alt="Service 3"
                  style={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "10px 10px 0 0",
                  }}
                />
                <div className="card-body">
                  <h5
                    className="card-title"
                    style={{
                      marginTop: "20px",
                      fontSize: "1.5rem",
                      fontWeight: "normal",
                    }}
                  >
                    Forensic examination of electronic devices and networks
                  </h5>
                  <p
                    className="card-text"
                    style={{
                      fontSize: "1.25rem",
                      lineHeight: "1.4",
                      marginTop: "15px",
                    }}
                  >
                    Forensic examination of electronic devices and networks
                    involves the analysis and collection of digital evidence to
                    uncover information related to a crime or security breach.
                  </p>
                </div>
              </div>
            </div>

            {/* Button */}
            <div className="text-center">
              <button
                style={{
                  color: "#0b0077",
                  borderColor: "#0b0077",
                  marginTop: "50px",
                }}
                className="btn btn-outline-light btn-lg"
              >
                <Link style={{ color: "#0b0077" }} to="/service">
                  Know More
                </Link>
              </button>
            </div>
          </div>

          <div
            style={{
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              color: "black",
              padding: "60px 0px",
            }}
          >
            {/* Heading */}
            <div className="text-center mb-5">
              <h1 style={{ fontSize: "3rem", fontWeight: "bold" }}>
                Testimonials
              </h1>
            </div>

            {/* Testimonials */}
            <div className="container">
              {/* First Row */}
              <div className="row mb-5">
                {/* Testimonial 1 */}
                <div className="col-12 col-lg-6 d-flex align-items-start gap-4 mb-4">
                  <img
                    src="https://duyn491kcolsw.cloudfront.net/files/1v/1vg/1vggel.png?ph=1ff354f48f"
                    alt="Person 1"
                    className="rounded-circle"
                    style={{ width: "100px", height: "100px" }}
                  />
                  <div>
                    <h2 style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                      Mary Mitchell
                    </h2>
                    <span
                      style={{
                        fontSize: "1.25rem",
                        fontStyle: "italic",
                        color: "#aaa",
                      }}
                    >
                      Data Security Analyst
                    </span>
                    <p
                      style={{
                        marginTop: "10px",
                        fontSize: "1rem",
                        lineHeight: "1.8",
                      }}
                    >
                      "Thanks to the expertise of the cyber forensics team, we
                      were able to successfully identify and stop a malicious
                      hacker who had been targeting our company's sensitive
                      data. Their quick response and thorough investigation
                      saved us from potential financial and reputational
                      damage."
                    </p>
                  </div>
                </div>

                {/* Testimonial 2 */}
                <div className="col-12 col-lg-6 d-flex align-items-start gap-4 mb-4">
                  <img
                    src="https://duyn491kcolsw.cloudfront.net/files/3l/3li/3lik65.png?ph=1ff354f48f"
                    alt="Person 2"
                    className="rounded-circle"
                    style={{ width: "100px", height: "100px" }}
                  />
                  <div>
                    <h2 style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                      Joe Carter
                    </h2>
                    <span
                      style={{
                        fontSize: "1.25rem",
                        fontStyle: "italic",
                        color: "#aaa",
                      }}
                    >
                      Information Security Consultant
                    </span>
                    <p
                      style={{
                        marginTop: "10px",
                        fontSize: "1rem",
                        lineHeight: "1.8",
                      }}
                    >
                      "Without the help of cyber forensics, we would have never
                      known the extent of the data breach that occurred in our
                      organization. Their in-depth analysis and detailed report
                      not only helped us identify the source of the breach but
                      also provided us with valuable insights to prevent future
                      incidents."{" "}
                    </p>
                  </div>
                </div>
              </div>

              {/* Second Row */}
              <div className="row">
                {/* Testimonial 3 */}
                <div className="col-12 col-lg-6 d-flex align-items-start gap-4 mb-4">
                  <img
                    src="https://duyn491kcolsw.cloudfront.net/files/3b/3b1/3b1651.png?ph=1ff354f48f"
                    alt="Person 3"
                    className="rounded-circle"
                    style={{ width: "100px", height: "100px" }}
                  />
                  <div>
                    <h2 style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                      Peter Miller
                    </h2>
                    <span
                      style={{
                        fontSize: "1.25rem",
                        fontStyle: "italic",
                        color: "#aaa",
                      }}
                    >
                      Digital Forensics Investigator
                    </span>
                    <p
                      style={{
                        marginTop: "10px",
                        fontSize: "1rem",
                        lineHeight: "1.8",
                      }}
                    >
                      "As a victim of cyber fraud, I was devastated and didn't
                      know where to turn. Thankfully, I was referred to a cyber
                      forensics specialist who helped me recover valuable
                      evidence and build a strong case against the perpetrator.
                      Their knowledge and support were invaluable in seeking
                      justice."{" "}
                    </p>
                  </div>
                </div>

                {/* Testimonial 4 */}
                <div className="col-12 col-lg-6 d-flex align-items-start gap-4 mb-4">
                  <img
                    src="https://duyn491kcolsw.cloudfront.net/files/30/304/304xff.png?ph=1ff354f48f"
                    alt="Person 4"
                    className="rounded-circle"
                    style={{ width: "100px", height: "100px" }}
                  />
                  <div>
                    <h2 style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                      Ashley Elegant
                    </h2>
                    <span
                      style={{
                        fontSize: "1.25rem",
                        fontStyle: "italic",
                        color: "#aaa",
                      }}
                    >
                      Founder, Creatives Studio
                    </span>
                    <p
                      style={{
                        marginTop: "10px",
                        fontSize: "1rem",
                        lineHeight: "1.8",
                      }}
                    >
                      Using blockchain analysis tools to trace and map
                      transactions on the blockchain to uncover patterns and
                      relationships between different entities. Thanks...
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* Button */}
            <div className="text-center">
              <button
                style={{
                  color: "#0b0077",
                  borderColor: "#0b0077",
                  marginTop: "50px",
                }}
                className="btn btn-outline-light btn-lg"
              >
                <Link style={{ color: "#0b0077" }} to="/testimonials">
                  Show More
                </Link>
              </button>
            </div>
          </div>

          <div
            style={{
              position: "relative",
              height: "400px",
              color: "white",
              textAlign: "center",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundImage:
                "url('https://1ff354f48f.clvaw-cdnwnd.com/aebbcd855c8771ef42699f56766d3f68/200000000-0f8a10f8a2/images-pexels-com-photos-5668765-pexels-photo-5668765-auto-compress-cs-tinysrgb-dpr-2-h-650-w-940.jpeg?ph=1ff354f48f')", // Replace with your image URL
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundBlendMode: "overlay",
              backgroundColor: "rgba(0, 0, 0, 0.5)", // Adds the dark overlay
            }}
          >
            {/* Content */}
            <h1 style={{ fontSize: "3rem", fontWeight: "300" }}>
              Do You Need to Contact Us?
            </h1>
            <button className="btn btn-outline-light btn-lg mt-3">
              <Link style={{ color: "white" }} to="/contact">
                GET IN TOUCH
              </Link>
            </button>
          </div>
          <Footer/>
        </div>
      </div>
    </>
  );
}

export default Home;
