import { Link, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { BASEURL } from "../../common/config";
import ComapnyNav from "../../components/CompanyNav";
import PublicHeader from "../../components/PublicHeader";
import axios from "axios";

export default function StorePostProduct() {
  const storeDetails = JSON.parse(localStorage.getItem("store-details"));
  let storeId = storeDetails._id
  const token = localStorage.getItem("store-token");

  const [image, setImage] = useState();
  const [productName, setproductName] = useState();
  const [Category, setCategory] = useState();
  const [CategoryID, setCategoryId] = useState();
  const [price, setPrice] = useState();

  const [description, setDescription] = useState();

  const [err, setErr] = useState(false);



  // Job category
  useEffect(() => {
    fetch(`${BASEURL}/category/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setCategory(data.data);
        console.log(data.data);
      })
      .catch((error) => {
        console.error("Fetch error:", error.message);
      });
  }, []);



  const handleCategory = (event) => {
    setCategoryId(event.target.options[event.target.selectedIndex].value);
  };


  const uploadImage = async (uploadImg) => {

    const data = new FormData();
    data.append("file", uploadImg);
    data.append( "upload_preset", process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET);
    data.append("cloud_name", process.env.REACT_APP_CLOUDINARY_CLOUD_NAME);
    data.append("folder", "Cloudinary-React");
    console.log(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/auto/upload`)
  
    try {
      const response = await fetch(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/auto/upload`,
        {
          method: "POST",
          body: data,
        }
      );
      const res = await response.json();
      console.log(res)
     
    
      return res.secure_url
      
    } catch (error) {
  
      return null
      }
    };


  const [loading,setLoading] = useState(false)
  const postProduct = async (e) => {
    e.preventDefault();
    if (
      productName === undefined ||
      !CategoryID ||
      description === undefined

    ) {
    
      setErr(true);
      return;
    }

    setLoading(true)
    let imageURL= await uploadImage(image)
    console.log(imageURL)

    let productDetails = {
      store_id: storeId,
      prodName: productName,
      category_id: CategoryID,
      description:  description,
      price: price,
      photo: imageURL
    }

    console.log(productDetails)

    let apiUrl = `${BASEURL}/create-product`
    let headers = {"Authorization": `Bearer ${token}`}
  
 

    axios.post(apiUrl, productDetails, {headers})
    .then((res)=>{
      console.log(res)
      if(res.status && res.status ===  200)
      alert(res.data.message)
    })
    .catch((err)=>{
     if(err.response && err.response.data && err.response.data.message){
      alert(err.response.data.message)
     }else{
      alert("An error eccurred. Please try agaen")
     }
    })
    .finally(()=>{
      setLoading(false)
    })

  };

  return (
    <div>
      {/* <!-- HEADER START --> */}
      <PublicHeader />
      {/* <!-- HEADER END --> */}
      {/* <!-- CONTENT START --> */}
      <div class="page-content">

        {/* <!-- OUR BLOG START --> */}
        <div class="section-full p-t120  p-b90 site-bg-white">


          <div class="container">
            <div class="row">

              <div class="col-xl-3 col-lg-4 col-md-12 rightSidebar m-b30">
                <ComapnyNav />
              </div>

              <div class="col-xl-9 col-lg-8 col-md-12 m-b30">
                <div class="twm-right-section-panel candidate-save-job site-bg-gray">
                  {/* <!--Filter Short By--> */}
                  {/* <!--Basic Information--> */}
                  <div className="panel panel-default">
                    <div className="panel-heading wt-panel-heading p-a20">
                      <h4 className="panel-tittle m-a0">
                        <i className="fa fa-suitcase"></i>Post Product
                      </h4>
                    </div>
                    <div className="panel-body wt-panel-body p-a20 m-b30 ">
                      <form onSubmit={postProduct}>
                        <div className="row">
                          {/* <!--Job title-->             */}
                          <div className="col-xl-4 col-lg-6 col-md-12">
                            <div className="form-group">
                              <label>Product Name</label>
                              <div className="ls-inputicon-box">
                                <input
                                  className="form-control"
                                  name="company_name"
                                  type="text"
                                  placeholder="Bag"
                                  onChange={(e) => setproductName(e.target.value)}
                                  value={productName}
                                />
                                <i className="fs-input-icon fa fa-address-card"></i>
                              </div>
                              <div>
                                {err === true && productName === undefined ? (
                                  <span>Enter Product Name</span>
                                ) : (
                                  productName === null
                                )}
                              </div>
                            </div>
                          </div>

                          {/* <!--Job Category-->  */}
                          <div className="col-xl-4 col-lg-6 col-md-12">
                            <div className="form-group city-outer-bx has-feedback">
                              <label>Category</label>
                              <div className="ls-inputicon-box ">
                                <select
                                  className="wt-select-box selectpicker form-select form-select-lg mb-3"
                                  data-live-search="true"
                                  title=""
                                  id="j-category"
                                  data-bv-field="size"
                                  onChange={handleCategory}
                                  value={CategoryID}
                                >
                                  <option value="">Select Category</option>
                                  {Category &&
                                    Category.map((cat) => (
                                      <option key={cat._id} value={cat._id}>
                                        {cat.categoryName}
                                      </option>
                                    ))}
                                </select>

                                <i className="fs-input-icon fa fa-border-all"></i>
                              </div>
                              <div>
                                {err === true && !CategoryID ? (
                                  <span>Enter category</span>
                                ) : (
                                  Category === null
                                )}
                              </div>
                            </div>
                          </div>


                          <div className="col-xl-4 col-lg-6 col-md-12">
                            <div className="form-group">
                              <label>Price</label>
                              <div className="ls-inputicon-box">
                                <input
                                  className="form-control"
                                  name="company_name"
                                  type="text"
                                  placeholder="10,000"
                                  onChange={(e) => setPrice(e.target.value)}
                                  value={price}
                                />
                                <i className="fs-input-icon fa fa-address-card"></i>
                              </div>
                              <div>
                                {err === true && price === undefined ? (
                                  <span>Enter price</span>
                                ) : (
                                  price === null
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="col-xl-4 col-lg-6 col-md-12">
                            <div className="form-group">
                              <label>product Image</label>
                              <div className="ls-inputicon-box">
                                <input
                                  className="form-control"
                                  name="company_name"
                                  type="file"
                                  placeholder="Devid Smith"
                                  onChange={(e) => setImage(e.target.files[0])}
                                
                                />
                                <i className="fs-input-icon fa fa-address-card"></i>
                              </div>
                              <div>
                              {err === true && !image && <span>Enter image</span>}
                              </div>
                            </div>
                          </div>
                      




                          {/* <!--Description--> */}
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Description</label>
                              <textarea
                                className="form-control"
                                rows="3"
                                placeholder="write a brief description about this product"
                                onChange={(e) => setDescription(e.target.value)}
                                value={description}
                              ></textarea>
                            </div>
                            <div>
                              {err === true && description === undefined ? (
                                <span>Enter description</span>
                              ) : (
                                description === null
                              )}
                            </div>
                          </div>
                      



                          <div className="col-lg-12 col-md-12">
                            <div className="text-left">
                              <button type="submit" className="site-button m-r5">
                                {
                                  loading ? "Processing..." : "Publish Product"
                                }
                              </button>
                              {/* <button
                          type="submit"
                          className="site-button outline-primary"
                        >
                          Save Draft
                        </button> */}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>


                </div>
              </div>

            </div>
          </div>
        </div>
        {/* <!-- OUR BLOG END --> */}



      </div>
      {/* <!-- CONTENT END --> */}
    </div>
  )


}
