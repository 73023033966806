import { Link, useNavigate } from "react-router-dom";
import Dash_Header from "../components/Dashheader";
import { useEffect, useState } from "react";
import { BASEURL } from "../common/config";
import axios from "axios";

export default function DashPostProduct() {
  const [stores, setStores] = useState([]);
  const [product, setProduct] = useState('');
  const [price, setPrice] = useState('');
  const [image, setImage] = useState();
  const [description, setDescription] = useState('');
  const [stock, setStock] = useState('');
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const Navigate = useNavigate();

  // Fetch stores (if necessary)
  useEffect(() => {
    fetch(`${BASEURL}/product`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setStores(data.data);
      })
      .catch((error) => {
        console.error("Fetch error:", error.message);
      });
  }, []);

  const uploadImageToCloudinary = async (image) => {
    const formData = new FormData();
    formData.append("file", image);
    formData.append("upload_preset", "yzuqi0jw"); // Replace with your upload preset

    try {
      const res = await axios.post("https://api.cloudinary.com/v1_1/dpkgdtztx/upload", formData);
      return res.data.secure_url; // Return the secure URL of the uploaded image
    } catch (error) {
      console.error("Error uploading image:", error);
      throw new Error("Image upload failed");
    }
  };

  const postProduct = async (e) => {
    e.preventDefault();
    if (!product || !price || !image || !description || !stock) {
      setErr(true);
      return;
    }

    setLoading(true);
    const adminToken = localStorage.getItem("smeemly-admin-token");

    try {
      // Upload the image and get the URL
      const imageUrl = await uploadImageToCloudinary(image);

      // Prepare product data
      const productData = {
        name: product,
        description,
        price,
        stock,
        image: imageUrl, // Send the Cloudinary image URL
      };

      const headers = { Authorization: `Bearer ${adminToken}` };
      const res = await axios.post(`${BASEURL}/product`, productData, { headers });

      if (res.status === 201) {
        alert(res.data.message);
        Navigate("/dash-manage-product");
      } else {
        alert("Could not create product, please try again");
      }
    } catch (err) {
      console.error(err);
      alert("An error occurred, please try again");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="page-wraper">
        <Dash_Header />
        <div id="content">
          <div className="content-admin-main">
            <div className="wt-admin-right-page-header clearfix">
              <h2>Post a Product</h2>
              <div className="breadcrumbs">
                <a href="">Home</a>
                <a href="">Dashboard</a>
                <span>Product Form</span>
              </div>
            </div>

            <div className="panel panel-default">
              <div className="panel-heading wt-panel-heading p-a20">
                <h4 className="panel-title m-a0">
                  <i className="fa fa-suitcase"></i>Product Details
                </h4>
              </div>
              <div className="panel-body wt-panel-body p-a20 m-b30">
                <form onSubmit={postProduct}>
                  <div className="row">
                    {/* Product Name */}
                    <div className="col-xl-4 col-lg-6 col-md-12">
                      <div className="form-group">
                        <label>Name</label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Bag"
                          onChange={(e) => setProduct(e.target.value)}
                          value={product}
                        />
                        {err && !product && (
                          <span style={{ color: "red" }}>Enter product name</span>
                        )}
                      </div>
                    </div>

                    {/* Price */}
                    <div className="col-xl-4 col-lg-6 col-md-12">
                      <div className="form-group">
                        <label>Price</label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="40000"
                          onChange={(e) => setPrice(e.target.value)}
                          value={price}
                        />
                        {err && !price && (
                          <span style={{ color: "red" }}>Enter price</span>
                        )}
                      </div>
                    </div>

                    {/* Stock */}
                    <div className="col-xl-4 col-lg-6 col-md-12">
                      <div className="form-group">
                        <label>Stock</label>
                        <input
                          className="form-control"
                          type="number"
                          placeholder="100"
                          onChange={(e) => setStock(e.target.value)}
                          value={stock}
                        />
                        {err && !stock && (
                          <span style={{ color: "red" }}>Enter stock</span>
                        )}
                      </div>
                    </div>

                    {/* Image */}
                    <div className="col-xl-4 col-lg-6 col-md-12">
                      <div className="form-group">
                        <label>Image</label>
                        <input
                          className="form-control"
                          type="file"
                          onChange={(e) => setImage(e.target.files[0])}
                        />
                        {err && !image && (
                          <span style={{ color: "red" }}>Enter image</span>
                        )}
                      </div>
                    </div>

                    {/* Description */}
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Description</label>
                        <textarea
                          className="form-control"
                          rows="3"
                          placeholder="Description of the product"
                          onChange={(e) => setDescription(e.target.value)}
                          value={description}
                        ></textarea>
                        {err && !description && (
                          <span style={{ color: "red" }}>Enter description</span>
                        )}
                      </div>
                    </div>
                  </div>

                  <button
                    className={`btn btn-primary ${loading ? "disabled" : ""}`}
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? 'Loading...' : 'Post Product'}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
