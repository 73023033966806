import React from 'react';
import Dash_Header from '../components/Dashheader';
import Footer from '../components/Footer';


const Contact = () => {
  return (
    <>
      <div className="page-wraper">
        <Dash_Header />

        <div id="content">
        <div className="py-5" style={{ backgroundColor: 'white', color: '#0b0077', padding: '0px 50px' }}>
      <div className="row">
        {/* Left Column */}
        <div className="text-center mb-5 mt-5">
    <h1 style={{ fontSize: "2.5rem", fontWeight: "bold" }}>Contact Us</h1>
  </div>
        <div className="col-md-6" style={{fontSize:"1rem"}}>
          
          <p>
            Our team will conduct a thorough assessment of your case, examining the details and circumstances surrounding the loss of your funds.
          </p>
          <p>
            Don't let financial setbacks define your future. Take the first step toward recovering your funds by contacting us today. Our dedicated team is ready to assist you in reclaiming what is rightfully yours. We will liaise with relevant parties, financial institutions, and any necessary authorities on your behalf.
          </p>
          <h5 className="mt-4">
            <strong style={{fontWeight:"normal"}}>
              Our goal is to streamline the communication process and expedite the recovery of your funds guarantee 100%.
            </strong>
          </h5>
          <div className="col">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3282.203436935653!2d-58.43648938476924!3d-34.6094467658886!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcca8f45bbdfed%3A0x840de1f3a7e11a9e!2sBuenos%20Aires%2C%20Argentina!5e0!3m2!1sen!2s!4v1699021248185!5m2!1sen!2s"
            width="100%"
            height="300"
            style={{ border: '0' }}
            allowFullScreen=""
            loading="lazy"
            title="Google Map"
          ></iframe>
          <h5 style={{fontWeight:"normal", marginTop:"15px", fontSize:"1.2rem"}}>Email: proc7753@gmail.com</h5>
          <h5 style={{fontWeight:"normal", marginTop:"15px", fontSize:"1.2rem"}}>Number: 1-812-226-2927</h5>
          <h5 style={{fontWeight:"normal", marginTop:"15px", fontSize:"1.2rem"}}>Telegram: @Cyberjethub</h5>
        </div>
        </div>

        {/* Right Column */}
        <div className="col-md-6" style={{fontSize:"1rem"}}>
          <h4 style={{fontSize:"1.3rem"}}>Contact us today to discuss how we can assist you in safeguarding your digital assets and protecting your business.</h4>
          <form>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Your Name
              </label>
              <input type="text" className="form-control" id="name" placeholder="Enter your name" />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input type="email" className="form-control" id="email" placeholder="@" />
            </div>
            <div className="mb-3">
              <label htmlFor="message" className="form-label">
                Message
              </label>
              <textarea className="form-control" id="message" rows="4" placeholder="Enter your message"></textarea>
            </div>
            <button type="submit" className="btn btn-light w-100">
              SUBMIT
            </button>
          </form>
        </div>
      </div>

      {/* Map */}
      <div className="row mt-5">
        
      </div>
    </div>
    <Footer/>
        </div>
        </div>
        </>
  );
};

export default Contact;
