import { Link } from "react-router-dom";
import PublicHeader from "../../components/PublicHeader";
import UserNav from "../../components/UserNav";
import { useEffect, useState } from "react";
import { BASEURL } from "../../common/config";
import axios from "axios";
import ComapnyNav from "../../components/CompanyNav";
export default function StoreProfile() {
  const storeDetails = JSON.parse(localStorage.getItem("store-details"));
  const token = localStorage.getItem("store-token");

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [userData, setUserData] = useState({});
  const [fullName, setFullName] = useState("");

  const [storeName, setStoreName] = useState("");
  const [jobCategory_id, setJobCategory_id] = useState("");
  const [experience, setExperience] = useState("");

  const [formImage, setFormImage] = useState("");
  const [formBanner, setFormBanner] = useState("");

  useEffect(() => {
    axios
      .get(`${BASEURL}/store/${storeDetails._id}`)
      .then((res) => {
        console.log(res.data.data);
        setFullName(res.data.data.fullName);
        setEmail(res.data.data.email);
        let phone = res.data.data.phone.slice(3, 13);
        console.log(phone);
        let modifyPhone = `0${phone}`;
        setPhone(modifyPhone);

        setStoreName(res.data.data.storeName);
        setFormBanner(res.data.data.logo);
        setFormImage(res.data.data.profile_photo);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      {/* <!-- HEADER START --> */}
      <PublicHeader />
      {/* <!-- HEADER END --> */}
      {/* <!-- CONTENT START --> */}
      <div class="page-content">
        {/* <!-- OUR BLOG START --> */}
        <div class="section-full p-t120  p-b90 site-bg-white">
          <div class="container">
            <div class="row">
              <div class="col-xl-3 col-lg-4 col-md-12 rightSidebar m-b30">
                <ComapnyNav />
              </div>

              <div class="col-xl-9 col-lg-8 col-md-12 m-b30">
                {/* <!--Filter Short By--> */}
                <div class="twm-right-section-panel site-bg-gray">
                  <form onSubmit={""}>
                    <div className="panel panel-default">
                      <div className="panel-heading wt-panel-heading p-a20">
                        <h4 className="panel-tittle m-a0">
                          Logo and Cover image
                        </h4>
                      </div>
                      <div className="col-lg-12 col-md-12 ">
                        <div className="dashboard-cover-pic">
                          <div
                            action="https://thewebmax.org/jobzilla/upload.php"
                            className="dropzone "
                          >
                            <div className="row mb-5">
                              <div className="col-lg-6 ">
                                <label className="mb-3 fw-bolder" htmlFor="">
                                  Store Logo
                                </label>
                                {/* <input type="file" placeholder="input company logo"  onChange={(e) => setFormImage(e.target.value)} className="form-control" /> */}

                                <figure className="h-75 w-100 ">
                                  <img
                                    src={formImage}
                                    alt=""
                                    className="h-100 w-100"
                                  />
                                </figure>
                              </div>
                              <div className="col-lg-6 ">
                                <label className="mb-3 fw-bolder" htmlFor="">
                                  Store Banner
                                </label>
                                {/* <input type="file" placeholder="input company baner image"  onChange={(e) => setFormBanner(e.target.value)} className="form-control" /> */}
                                <figure className="w-100 h-75">
                                  <img
                                    src={formBanner}
                                    alt=""
                                    className="w-100 h-100"
                                  />
                                </figure>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!--Basic Information--> */}
                    <div class="panel panel-default">
                      <div class="panel-heading wt-panel-heading p-a20">
                        <h4 class="panel-tittle m-a0">Basic Informations</h4>
                      </div>
                      <div class="panel-body wt-panel-body p-a20 m-b30 ">
                        <div class="row">
                          <div class="col-xl-6 col-lg-6 col-md-12">
                            <div class="form-group">
                              <label>Holder Name</label>
                              <div class="ls-inputicon-box">
                                <input
                                  class="form-control"
                                  name="company_name"
                                  type="text"
                                  disabled
                                  onChange={(e) => setFullName(e.target.value)}
                                  value={fullName}
                                  placeholder="Devid Smith"
                                />
                                <i class="fs-input-icon fa fa-user "></i>
                              </div>
                            </div>
                          </div>

                          <div class="col-xl-6 col-lg-6 col-md-12">
                            <div class="form-group">
                              <label>Phone</label>
                              <div class="ls-inputicon-box">
                                <input
                                  class="form-control"
                                  name="company_phone"
                                  disabled
                                  onChange={(e) => setPhone(e.target.value)}
                                  value={phone}
                                  type="text"
                                  placeholder="e.g (251) 1234-456-7890"
                                />
                                <i class="fs-input-icon fa fa-phone-alt"></i>
                              </div>
                            </div>
                          </div>

                          <div class="col-xl-6 col-lg-6 col-md-12">
                            <div class="form-group">
                              <label>Email Address</label>
                              <div class="ls-inputicon-box">
                                <input
                                  class="form-control"
                                  name="company_Email"
                                  disabled
                                  onChange={(e) => setEmail(e.target.value)}
                                  value={email}
                                  type="email"
                                  placeholder="e.g Devid@example.com"
                                />
                                <i class="fs-input-icon fas fa-at"></i>
                              </div>
                            </div>
                          </div>

                          <div class="col-xl-6 col-lg-6 col-md-12">
                            <div class="form-group">
                              <label>Store</label>
                              <div class="ls-inputicon-box">
                                <input
                                  class="form-control"
                                  name="company_name"
                                  type="text"
                                  disabled
                                  onChange={(e) => setStoreName(e.target.value)}
                                  value={storeName}
                                  placeholder="e.g Microsoft Inc"
                                />
                                <i class="fs-input-icon fa fa-building "></i>
                              </div>
                            </div>
                          </div>

                          <div class="col-xl-12 col-lg-12 col-md-12">
                            <div class="form-group">
                              <label>Store link</label>
                              <div class="ls-inputicon-box">
                                <input
                                  class="form-control"
                                  name="company_name"
                                  type="text"
                                  disabled
                                  value={
                                    "https://5amasgroup.com.ng/" + storeName
                                  }
                                  placeholder="e.g Microsoft Inc"
                                />
                                <i class="fs-input-icon fa fa-building "></i>
                              </div>
                            </div>
                          </div>

                          {/* <div class="col-lg-12 col-md-12">
                                                        <div class="text-left">
                                                            <button type="submit" class="site-button">Save Changes</button>
                                                        </div>
                                                    </div> */}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- OUR BLOG END --> */}
      </div>
      {/* <!-- CONTENT END --> */}
    </div>
  );
}
