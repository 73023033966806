import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import logo from "../assets/cyberjet.png"
import back from "../assets/logo3.jpeg"
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <footer
  className="bg-dark text-dark py-4"
  style={{
    background: `linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url(${back})`,
    backgroundSize: "cover", // Optional: Ensures the image covers the area
    backgroundRepeat: "no-repeat", // Optional: Prevents repeating the image
    backgroundPosition: "center", // Optional: Centers the background image
    padding: "0px 60px"
  }}
>
      <Container>
        <Row className="align-items-center">
          {/* Logo Section */}
          <Col md={4} className="text-center text-md-start">
          <div className="logo">
          <Link to="/"><img src={logo} alt="Logo" style={{height:"50px"}} /></Link>
        </div>
          </Col>

          {/* Links Section */}
          <Col md={4} className="text-center">
            <p>&copy; CyberJet Hub 2024</p>
          </Col>

          {/* Social Media Section */}
          <Col md={4} className="text-center text-md-end">
            <a
              href="https://www.facebook.com/share/17X5qFjd3h/?mibextid=LQQJ4d"
              target="_blank"
              rel="noopener noreferrer"
              className="text-dark me-3"
            >
              <FaFacebook size={30} />
            </a>
            <a
              href="https://www.instagram.com/cyberjethub/profilecard/?igsh=MTFuN2pzdXVpd2hxNw=="
              target="_blank"
              rel="noopener noreferrer"
              className="text-dark"
            >
              <FaInstagram size={30} />
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
