import Dash_Header from "../components/Dashheader";
import Footer from "../components/Footer";

export default function About() {
  return (
    <>
      <div className="page-wraper">
        <Dash_Header />

        <div id="content">
          <div
            style={{
              backgroundColor: "white",
              color: "black",
              padding: "130px 50px",
            }}
          >
            <div className="text-center mb-5">
              <h1
                style={{
                  fontSize: "2.5rem",
                  fontWeight: "bold",
                  color: "#0b0077",
                }}
              >
                About Us
              </h1>
            </div>

            <div className="d-flex flex-column flex-lg-row align-items-center">
              {/* Image on the Left */}
              <div className="mb-4 mb-lg-0" style={{ flex: "1 1 50%" }}>
                <img
                  src="https://1ff354f48f.clvaw-cdnwnd.com/aebbcd855c8771ef42699f56766d3f68/200000004-197a8197aa/images-pexels-com-photos-4100672-pexels-photo-4100672-auto-compress-cs-tinysrgb-dpr-2-h-650-w-940.jpeg?ph=1ff354f48f"
                  alt="About Us"
                  style={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "15px",
                  }}
                />
              </div>

              {/* Text on the Right */}
              <div className="ms-lg-5" style={{ flex: "1 1 50%" }}>
                <p style={{ fontSize: "1.25rem", lineHeight: "1.4" }}>
                  <span style={{ fontWeight: "bold" }}>Cyber Jet Hub</span>, is
                  a crucial field in today's digital age where cybercrime is
                  becoming increasingly prevalent. Our team of experts
                  specialize in collecting, analyzing, and preserving electronic
                  evidence to uncover and prevent cyber attacks. We work closely
                  with law enforcement agencies and organizations to investigate
                  and prosecute cybercriminals, ensuring justice is served and
                  the integrity of digital data is maintained. With the rapid
                  advancements in technology, cyberjet hub plays a crucial role
                  in protecting individuals, businesses, and governments from
                  the ever-evolving threats of the digital world. Our dedication
                  to staying updated with the latest techniques and tools allows
                  us to provide top-notch services to our clients and contribute
                  towards a safer cyber landscape.
                </p>
                <p style={{ fontSize: "1.25rem", lineHeight: "1.4" }}>
                  The history of cyberjet hub can be traced back to the
                  with the rise of computer technology and the use of computers
                  in criminal activities. The first recorded use of computer
                  forensics was in ...., when a computer was used as evidence in
                  a murder trial. As technology continued to advance, so did the
                  methods and techniques used in cyber forensics. In the ....s,
                  the term "computer forensics" was coined and the field began
                  to gain recognition as a crucial aspect of investigating
                  cybercrime.
                </p>
              </div>
            </div>
            <p
              style={{
                fontSize: "1.4rem",
                lineHeight: "1.4",
                color: "GrayText",
                marginTop: "20px",
              }}
            >
              It's great that you're committed to providing a high level of
              service and guaranteeing satisfaction for your customers.
              Guaranteeing your service is a strong statement of confidence in
              your abilities and can build trust with your clients. We will
              guarantee to recover 100% of your loss.{" "}
            </p>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
